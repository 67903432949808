import React from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import MultiSelectList from "../select/multi-select-list";
import { QualityStatusFormData } from "./recipe-quality-status";

interface props {
  isOpen: boolean;
  onCancel: any;
  onSave: React.MouseEventHandler<any>;
  onEdit: React.MouseEventHandler<any>;
  handleChange: React.ChangeEventHandler<HTMLInputElement>;
  formData: QualityStatusFormData;
  recipeQualityCriterias: Array<any>;
  onCriteriaChange: (uuids: string[]) => void;
}
const QualityStatusEditModal = (props: props) => {
  const { t } = useTranslation();
  return (
    <div>
      <Modal isOpen={props.isOpen}>
        <ModalHeader toggle={props.onCancel}>
          {props.formData.statusUuid !== ""
            ? t("recipe.editRecipeQualityStatus")
            : t("recipe.addRecipeQualityStatus")}
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup row className="p-0">
              <Col sm={12}>
                <Label className="text-right" for="name">
                  {t("recipe.qualityStatusName")}
                  <span className="required-star-style">*</span>
                </Label>
                <Input
                  type="text"
                  name="name"
                  value={props.formData.name}
                  onChange={props.handleChange}
                />
              </Col>
            </FormGroup>
            <FormGroup>
              <Label for="colour">
                {t("common.colour")}
                <span className="required-star-style">*</span>
              </Label>
              <Input
                type="color"
                name="colour"
                value={props.formData.colour}
                onChange={props.handleChange}
              />
            </FormGroup>
            <FormGroup row className="p-0">
              <Col sm={12}>
                <Label className="text-right" for="value">
                  {t("recipe.assignQualityCriteria")}
                </Label>
                <MultiSelectList
                  id={props.formData?.statusUuid || "qs"}
                  items={props.recipeQualityCriterias}
                  initialSelectedUuids={props.formData.criterias}
                  onSelectionChange={props.onCriteriaChange}
                  pageSize={20}
                />
              </Col>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={props.onCancel}>
            {t("common.cancel")}
          </Button>
          <Button
            color="info"
            onClick={
              props.formData.statusUuid !== "" ? props.onEdit : props.onSave
            }
            disabled={props.formData.name?.length === 0}
          >
            {t("common.save")}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default QualityStatusEditModal;
