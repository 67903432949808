import React from "react";
import { isEmpty } from "lodash";
import { Badge } from "reactstrap";
import { useTranslation } from "react-i18next";
import { RECIPE_STATUS } from "../../constant/constant";

interface StatusBadgeProps {
  status: number;
  qualityStatus: any;
  isRecipe: boolean;
}

const RecipeStatusBadge = (props: StatusBadgeProps) => {
  const { status, qualityStatus, isRecipe } = props;
  const { t } = useTranslation();
  if (!isRecipe && status !== RECIPE_STATUS.ARCHIVED) return <></>;

  const getStatusDetails = () => {
    let statusName = "";
    let className = "badge-secondary ";
    let color = "";
    let backgroundColor = "";

    switch (status) {
      case RECIPE_STATUS.DRAFT:
        statusName = t("recipe.draft");
        className += "bf-badge-bg";
        break;
      case RECIPE_STATUS.PUBLISHED:
        statusName = t("recipe.published");
        color = "success";
        if (!!qualityStatus?.name) {
          color = "";
          statusName = `${t("recipe.published")} - ${qualityStatus.name}`;
          backgroundColor = qualityStatus?.colour;
        }
        break;
      case RECIPE_STATUS.ARCHIVED:
        statusName = t("recipe.archived");
        backgroundColor = "#A020F0";
        break;
    }

    return { statusName, className, color, backgroundColor };
  };

  const { statusName, className, color, backgroundColor } = getStatusDetails();

  return (
    <Badge
      className={className}
      color={color}
      style={
        !isEmpty(backgroundColor) ? { backgroundColor: backgroundColor } : {}
      }
    >
      {statusName}
    </Badge>
  );
};

export default RecipeStatusBadge;
