import React from "react";
import { Col, Row } from "reactstrap";
import {
  faEdit,
  faEllipsisV,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { PHILIPPINE_GRAY } from "../../constant/constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function QualityStatusCard(props: any) {
  return (
    <div className="task-type p-0 mb-2">
      <div className="task-type assigned card-component">
        <Row key={props.uuid}>
          <Col
            style={{ color: props.item.colour, fontWeight: "bold" }}
            md={3}
            lg={3}
            sm={3}
            className="p-0 pl-2"
          >
            <span className="mr-2">
              <FontAwesomeIcon
                className="b-drag-icon mr-1"
                style={{
                  color: PHILIPPINE_GRAY,
                }}
                icon={faEllipsisV}
              />
            </span>
            {props.item.name}
          </Col>
          <Col md={8} lg={8} sm={8}>
            <ul className="pl-3 mb-0">
              {props.item?.criterias.map((c: any, i: any) => {
                return (
                  <li key={i} className="bf-font-size-14">
                    {c.description}
                  </li>
                );
              })}
            </ul>
          </Col>
          <Col md={1} lg={1} sm={1} className="p-0">
            <span
              className="px-1 pointer-cursor"
              onClick={() => {
                props.onEdit(props.item.uuid);
              }}
            >
              <FontAwesomeIcon
                icon={faEdit}
                style={{
                  fontSize: 16,
                }}
              />
            </span>
            <span
              className="px-1 pointer-cursor"
              onClick={() => {
                props.onDelete(props.item.uuid);
              }}
            >
              <FontAwesomeIcon
                icon={faTrashAlt}
                style={{
                  fontSize: 16,
                }}
              />
            </span>
          </Col>
        </Row>
      </div>
    </div>
  );
}
