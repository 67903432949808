import React from "react";
import { useTranslation } from "react-i18next";
import QualityStatusCard from "./quality-status-card";
import DragAndDropWrapper from "../drag-drop-component";

export default function QualityStatusList(props: any) {
  const { t } = useTranslation();
  const getChildrenProps = (card: any, index: any) => {
    return {
      uuid: card.uuid,
      item: card,
      onEdit: props.onEdit,
      onDelete: props.onDelete,
    };
  };

  const onDragEnd = (drag: any) => {
    if (!drag.destination) {
      return;
    }
    const list = [...props.statusList];
    const [removed] = list.splice(drag.source.index, 1);
    list.splice(drag.destination.index, 0, removed);

    props.setStatusList(list);
    props.onStatusSortUpdate(
      list.map((s, i) => ({ uuid: s.uuid, sortOrder: i + 1 }))
    );
  };

  return (
    <DragAndDropWrapper
      getChildrenProps={getChildrenProps}
      onDragEnd={onDragEnd}
      itemsList={props.statusList}
    >
      <QualityStatusCard />
    </DragAndDropWrapper>
  );
}
