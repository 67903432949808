import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Col, Row } from "reactstrap";
import {
  InventoryInitState,
  InventoryState,
} from "../../models/inventory.model";
import { ingredientsRequest } from "../../redux/actions/ingredients.action";
import { inventoryRequest } from "../../redux/actions/inventory.action";
import { recipeRequest } from "../../redux/actions/recipe.action";
import { unitsRequest } from "../../redux/actions/units.action";
import "../../styles/inventory.scss";
import InventoryCard from "../card-components/inventory-card/inventory-card";
import LoaderComponent from "../loader/loader";
import OperateHeaderComponent from "../navigation/navigation-header/operate-header/operate-header";
import OperateLeftComponent from "../navigation/navigation-left/operate-navigation/operate-navigation";
import NavigationRightComponent from "../navigation/navigation-right/navigation-right";
import { setRestaurantLanguage } from "../../config/lang.config";
import { withTranslation } from "react-i18next";
import commonService from "../../services/common.service";
import _ from "lodash";

class InventoryComponent extends Component<any> {
  state: InventoryState;
  constructor(props: any) {
    super(props);
    this.state = InventoryInitState;
  }
  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <LoaderComponent display={!!this.state.isFetching} />
        <div className="container-fluid">
          <OperateHeaderComponent operate={"operate"} />
          <Row className="main light-theme">
            <Col xl={2} lg={3} className="hide-left-max">
              <OperateLeftComponent
                display={"inventory"}
                restaurantuuid={this.props.match.params.uuid}
              />
            </Col>
            <Col xl={8} lg={6}>
              <div className="white-box mb-3">
                <h4>{t("inventory.batchArchive")}</h4>
                {this.getInventoryList()}
              </div>
            </Col>
            <Col xl={2} lg={3}>
              <NavigationRightComponent />
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }

  public getInventoryList() {
    let inventory;

    if (!!this.state.inventory && this.state.inventory.length > 0) {
      const sortedInventory: Array<any> = [];
      const allRecipes: any = this.props.recipes.concat(this.props.ingredient);

      this.state.inventory.forEach((inv: any) => {
        const [item] = allRecipes.filter((recipe: any) => {
          return recipe.uuid === inv.recipe;
        });

        if (item) {
          inv["name"] = commonService
            .applyLocalization("restaurant", "name", item.locales)
            ["name"].toLowerCase();
        }
        sortedInventory.push(inv);
      });

      inventory = _.sortBy(sortedInventory, "name").map(
        (value: any, index: number) => {
          return (
            <InventoryCard
              key={index}
              inventory={value}
              recipe={allRecipes}
              units={this.props.units}
            />
          );
        }
      );
      return inventory;
    } else {
      return (
        <div style={{ width: "100%", textAlign: "center" }}>
          {this.props.t("common.noRecords")}
        </div>
      );
    }
  }

  componentDidMount() {
    document.body.className = "light-theme";
    this.props.getInventory({ restaurantuuid: this.props.match.params.uuid });
    this.props.recipe({ uuid: this.props.match.params.uuid });
    this.props.ingredients({ uuid: this.props.match.params.uuid });
    this.props.getUnits({ restaurantuuid: this.props.match.params.uuid });
  }

  UNSAFE_componentWillReceiveProps(newProps: any) {
    if (!!newProps) {
      this.setState({
        isFetching: newProps.isFetching,
      });
      if (!!newProps && !newProps.isFetching && !newProps.failure) {
        this.setState({
          inventory: newProps.inventories,
        });
      }
    }
  }
}

const mapStateToProps: any = (state: any) => {
  let isFetching =
    state.inventory.isFetching ||
    state.recipe.isFetching ||
    state.ingredients.isFetching ||
    state.units.isFetching;
  let failure =
    state.inventory.failure ||
    state.recipe.failure ||
    state.ingredients.failure ||
    state.units.failure;
  return {
    recipes: state.recipe.data,
    ingredient: state.ingredients.data,
    inventories: state.inventory.data,
    units: state.units.data,
    isFetching: isFetching,
    failure: failure,
  };
};

const mapDispatchToProps: object = (dispatch: any) => {
  return {
    getInventory: (credentials: any) => {
      dispatch(inventoryRequest(credentials));
    },
    recipe: (credentials: any) => {
      dispatch(recipeRequest(credentials));
    },
    ingredients: (credentials: any) => {
      dispatch(ingredientsRequest(credentials));
    },
    getUnits: (credentials: any) => {
      dispatch(unitsRequest(credentials));
    },
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(InventoryComponent))
);
