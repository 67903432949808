import { sortBy } from "lodash";
import { useParams } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import LoaderComponent from "../loader/loader";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import QualityGroupList from "./quality-group-list";
import commonService from "../../services/common.service";
import { RESTAURANT_REQUEST } from "../../config/api.config";
import handleResponse from "../../services/response.service";
import QualityGroupEditModal from "./quality-group-edit-modal";
import groupService from "../../services/recipe.quality.group.service";
import { RecipeQualityHelper } from "../../helpers/recipeQualityHelper";
import DeleteModalCard from "../card-components/delete-card/delete-card";
import NavigationRightComponent from "../navigation/navigation-right/navigation-right";
import ConceptHeaderComponent from "../navigation/navigation-header/concept-header/concept-header";
import RestaurantNavComponent from "../navigation/navigation-left/restaurant-navigation/restaurant-navigation";

type ParamTypes = {
  uuid: string;
};

const RecipeQualityGroupComponent = () => {
  const { uuid } = useParams<ParamTypes>();
  const { t } = useTranslation();
  const restaurantLang = commonService.getRestaurantDetails()?.languages || [];
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState<boolean>(false);
  const [groupList, setGroupList] = useState<Array<any>>([]);
  const [groupUuid, setGroupUuid] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [mandatory, setMandatory] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [recipeQualityCriterias, setRecipeQualityCriterias] = useState<
    Array<any>
  >([]);
  const [criterias, setCriterias] = useState<string[]>([]);

  useEffect(() => {
    RecipeQualityHelper.getGroupList(uuid, setGroupList, setIsFetching);
    RecipeQualityHelper.getCriteriaList(
      uuid,
      setRecipeQualityCriterias,
      setIsFetching
    );
  }, [uuid]);

  const setEditModalToggle = () => {
    setIsEditModalOpen(!isEditModalOpen);
  };

  const saveQualityGroup: React.MouseEventHandler<any> = (event) => {
    setIsFetching(true);
    const payLoad = {
      restaurantuuid: uuid,
      data: {
        locales: {
          [restaurantLang[0].code]: {
            name: name,
          },
        },
        mandatory: mandatory,
        criterias: criterias,
      },
    };
    groupService
      .addRecipeQualityGroup(RESTAURANT_REQUEST, payLoad)
      .then((response: any) => {
        handleResponse(response);
        if (response.ok) {
          setEditModalToggle();
          RecipeQualityHelper.getGroupList(uuid, setGroupList, setIsFetching);
        }
        setIsFetching(false);
      });
  };

  const editQualityGroup: React.MouseEventHandler<any> = (event) => {
    setIsFetching(true);
    const payLoad = {
      restaurantuuid: uuid,
      uuid: groupUuid,
      data: {
        locales: {
          [restaurantLang[0].code]: {
            name: name,
          },
        },
        mandatory: mandatory,
        criterias: criterias,
      },
    };
    groupService
      .updateRecipeQualityGroup(RESTAURANT_REQUEST, payLoad)
      .then((response: any) => {
        handleResponse(response);
        if (response.ok) {
          setEditModalToggle();
          RecipeQualityHelper.getGroupList(uuid, setGroupList, setIsFetching);
        }
        setIsFetching(false);
      });
  };

  const addRecipeQualityGroup = () => {
    setGroupUuid("");
    setName("");
    setMandatory(false);
    setCriterias([]);
    setEditModalToggle();
  };

  const initEditQualityGroup = (groupUuid: string) => {
    setGroupUuid(groupUuid);
    const qualityGroup = groupList.find(
      (item, index) => item.uuid === groupUuid
    );
    setName(qualityGroup.name);
    setMandatory(qualityGroup.mandatory);
    setCriterias(qualityGroup?.criterias?.map((c: any) => c.uuid));
    setEditModalToggle();
  };

  const removeModalToggle = () => {
    setIsRemoveModalOpen(!isRemoveModalOpen);
  };

  const initRemoveQualityGroup = (groupUuid: string) => {
    setGroupUuid(groupUuid);
    removeModalToggle();
  };

  const removeQualityGroup: React.MouseEventHandler<any> = (event) => {
    setIsFetching(true);
    const payLoad = {
      restaurantuuid: uuid,
      uuid: groupUuid,
    };
    groupService
      .removeRecipeQualityGroup(RESTAURANT_REQUEST, payLoad)
      .then((response: any) => {
        handleResponse(response);
        if (response.ok) {
          removeModalToggle();
          RecipeQualityHelper.getGroupList(uuid, setGroupList, setIsFetching);
        }
        setIsFetching(false);
      });
  };

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    if (event.target.name === "name") {
      setName(event.target.value);
    }
    if (event.target.name === "mandatory") {
      setMandatory(!mandatory);
    }
  };

  return (
    <div>
      <LoaderComponent display={isFetching} />

      <QualityGroupEditModal
        isOpen={isEditModalOpen}
        onCancel={setEditModalToggle}
        onSave={saveQualityGroup}
        onEdit={editQualityGroup}
        handleChange={handleChange}
        uuid={groupUuid}
        name={name}
        mandatory={mandatory}
        recipeQualityCriterias={recipeQualityCriterias}
        criterias={criterias}
        onCriteriaChange={setCriterias}
      />

      <DeleteModalCard
        isOpen={isRemoveModalOpen}
        uuid={groupUuid}
        okDelete={removeQualityGroup}
        cancelDelete={removeModalToggle}
      />

      <div className="container-fluid">
        <ConceptHeaderComponent concept={"concept"} />
        <Row className="main light-theme">
          <Col xl={2} lg={3} className="hide-left-max">
            <RestaurantNavComponent
              display={"recipe-quality-groups"}
              restaurantuuid={uuid}
            />
          </Col>
          <Col xl={8} lg={6}>
            <div className="white-box mb-3">
              <h4>
                {t("common.recipeQualityGroups")}
                <Button
                  className="ml-2"
                  color="primary"
                  size="sm"
                  onClick={addRecipeQualityGroup}
                >
                  {t("recipe.addRecipeQualityGroup")}
                </Button>
              </h4>
              <QualityGroupList
                restaurantLang={restaurantLang}
                groupList={groupList}
                onEdit={initEditQualityGroup}
                onDelete={initRemoveQualityGroup}
              />
            </div>
          </Col>
          <Col xl={2} lg={3}>
            <NavigationRightComponent />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default RecipeQualityGroupComponent;
