import { unitModalPayload } from "./units.model";

export interface ServiceItemModel {
  readonly isFetching: boolean;
  readonly data: object;
  readonly error: object;
  readonly failure: boolean;
}

export interface ItemWrapperState {
  activeKey: string;
  addmodal: boolean;
  barcode: string;
  color: string;
  contentList: Array<any>;
  conversionId: string;
  conversionOptions: Array<any>;
  editmodal: boolean;
  defaultValue: any;
  description: string;
  fetchData: boolean;
  filedata: any;
  fiterFlag: boolean;
  filterParams: any;
  grossWeight: string;
  grossWeightUnitSelection: any;
  isManged: string;
  itemDetail: any;
  isFetching: boolean;
  isRecipeUpdated: boolean;
  isAddItemModalOpen: boolean;
  isSubstituteListUpdated: boolean;
  isUnitModalOpen: boolean;
  isUpdated: boolean;
  labels: Array<any>;
  moreValues: Array<any>;
  name: string;
  otherUnit: any;
  outputquantity: string;
  outputunituuid: string;
  override: boolean;
  parameter: string;
  placeuuid: string;
  posName: string;
  printerName: string;
  rackuuid: string;
  richText: boolean;
  recipedetaildata: any;
  recipeSubstitute: any;
  recipeSubstituteUuid: string;
  recipeQuantity: string;
  rooms: Array<any>;
  roomuuid: string;
  searchByName: string;
  selectedFilterOptions: any;
  searchList: any;
  selectedLabels: any;
  selectedSupplier: any;
  selectedServiceSetData: any;
  supplierList: any;
  sku: string;
  showAddTag: boolean;
  showEditModal: boolean;
  showModal: boolean;
  showServiceSetModal: boolean;
  showSubstituteModal: boolean;
  storage: Array<any>;
  storageuuid: string;
  symbol: string;
  tagId: string;
  tagName: any;
  tagOptions: Array<any>;
  tagValue: any;
  toggleStorage: boolean;
  toggleStorageItem: boolean;
  updateList: boolean;
  unitId: string;
  unitModalPayload: unitModalPayload;
  uuid: string;
  replacementItem: string;
  parentLabels: Array<any>;
  offeringSupplier: any;
  printerTemplate: Array<any>;
  qualityStatuses: Array<any>;
  selectedStatus: { label: string; value: string };
  tagRestriction: any;
}

export const ItemWrapperInitState: ItemWrapperState = {
  activeKey: "",
  addmodal: false,
  barcode: "",
  color: "#FFFFFF",
  contentList: [],
  conversionId: "",
  conversionOptions: [],
  defaultValue: {},
  description: "",
  editmodal: false,
  fetchData: false,
  filedata: {},
  fiterFlag: false,
  filterParams: {},
  grossWeight: "",
  grossWeightUnitSelection: null,
  isAddItemModalOpen: false,
  isManged: "1",
  isFetching: false,
  isRecipeUpdated: false,
  isSubstituteListUpdated: false,
  isUnitModalOpen: false,
  isUpdated: false,
  itemDetail: {},
  labels: [],
  moreValues: [],
  name: "",
  otherUnit: {},
  outputquantity: "",
  outputunituuid: "",
  override: false,
  parameter: "",
  placeuuid: "",
  posName: "",
  printerName: "",
  recipedetaildata: {},
  recipeSubstitute: {},
  recipeSubstituteUuid: "",
  recipeQuantity: "",
  rackuuid: "",
  richText: false,
  rooms: [],
  roomuuid: "",
  searchByName: "",
  searchList: [],
  selectedFilterOptions: [],
  selectedLabels: [],
  selectedServiceSetData: {},
  selectedSupplier: [],
  supplierList: [],
  sku: "",
  showAddTag: false,
  showEditModal: false,
  showModal: false,
  showServiceSetModal: false,
  showSubstituteModal: false,
  storage: [],
  storageuuid: "",
  symbol: "",
  tagId: "",
  tagOptions: [],
  tagName: {},
  tagValue: [],
  toggleStorage: false,
  toggleStorageItem: false,
  updateList: true,
  unitId: "",
  unitModalPayload: {
    grossWeight: "",
    grossWeightUnitSelection: null,
    hasGrossWeight: true,
    name: "",
    symbol: "",
  },
  uuid: "",
  replacementItem: "",
  parentLabels: [],
  offeringSupplier: {},
  printerTemplate: [],
  qualityStatuses: [],
  selectedStatus: { label: "", value: "" },
  tagRestriction: {},
};

export interface itemWrapperProps {
  addButtonTitle: any;
  addConversion: Function;
  addNewStorage: Function;
  addSubstitute: Function;
  addUnits: Function;
  allStorage: Array<any>;
  area: string;
  assignTags: any;
  cardItemSelectionData: any;
  cardType: string;
  clearShoppingCart: Function;
  contents: Array<any>;
  currentPage: any;
  deleteConversion: Function;
  deliveryDate: any;
  orderComment: string;
  editCartItem: Function;
  editConversion: Function;
  editStorage: Function;
  editKeyValue: Function;
  elementId: string;
  extraNavButtons?: Function;
  fetchData: any;
  filterParams: any;
  getBestMatchUnits: any;
  getContent?: any;
  getExtraDetails: any;
  getItems: any;
  getLabel?: any;
  getRecipeDetail: any;
  getRecipeSubstitutes: any;
  getServiceSet?: any;
  getSettings?: any;
  getSubstitutes?: any;
  getSupplierDetails?: any;
  getTags?: any;
  getUnitLabelName: Function;
  getUnits: any;
  getUpdatedCostPrices: Function;
  handleChange: Function;
  handleCostUpdate: Function;
  handleDateChange: Function;
  handleCommentChange: Function;
  handleImageUpdate: Function;
  handleIngredientImport: Function;
  handleSelect: Function;
  handleSelectChange: any;
  handleTagChange: Function;
  handleUnitUpdate: Function;
  hasCopy: boolean;
  hasDetails: boolean;
  hasPagination: boolean;
  hasTag: boolean;
  hasTags: boolean;
  hideFilter: boolean;
  ingredientsCost: any;
  isDetailPage?: boolean;
  isFetching: boolean;
  isSupplierOffering: boolean;
  isUnitModalOpen: boolean;
  isUpdated: boolean;
  itemData: Array<any>;
  itemsDetails: any;
  labels: Array<any>;
  match: any;
  onAddNewItem: any;
  onItemCopy: Function;
  onItemDelete: Function;
  onItemEdit: Function;
  onMoveToDetailPage: Function;
  openOrderModal: Function;
  pageText: any;
  paymentProvider: any;
  placeOrder: Function;
  quantity: any;
  recipeDetails: any;
  recipeServiceSet: any;
  recipeStorage: Array<any>;
  recipeSubstitutes: Array<any>;
  removeCartItem: Function;
  removeRecipeSubstitute: Function;
  removeStorage: Function;
  removeUnits: Function;
  restaurantLang: any;
  restaurantOptions: Array<any>;
  rooms: Array<any>;
  saveOrder: Function;
  saveRecipeSubstitute: Function;
  selectedRecipe: Function;
  serviceset: any;
  setCurrentPage: Function;
  setFilterParams: any;
  setItemId: Function;
  settings: any;
  shoppingCartArray: Function;
  showCartModal: Function;
  showOrderModal: Function;
  showItemCount: boolean;
  substitutes: Array<any>;
  supplierOptions: Array<any>;
  supplierUnits: Array<any>;
  t: any;
  tags: Array<any>;
  toggleCartModal: Function;
  toggleOrderModal: Function;
  totalCount: string | number;
  totalPages: any;
  type?: string;
  units: Array<any>;
  unitsAdd: any;
  upDateRecipeSubstitute: Function;
  updateUnits: Function;
  uploadImage: Function;
  importedItems: object;
  supplierLabels: Array<any>;
  supplierInventoryInfo?: Array<any>;
  alphabeticalSortFlag?: boolean;
  setAlphabeticalSortFlag?: Function;
  getPrinterTemplates: any;
  printerTemplate: Array<any>;
  supplierActiveImports?: Array<any>;
  isBrowserNav?: boolean;
}
