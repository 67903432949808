import React from "react";
import _ from "lodash";
import {
  faCalendarDay,
  faListOl,
  faSortAmountDown,
} from "@fortawesome/free-solid-svg-icons";
import { SupplierHelper } from "./supplierHelper";
import commonService from "../services/common.service";
import recipeIcon from "../assets/images/icon/recipeIcon.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ingredientIcon from "../assets/images/icon/ingredientsIcon.svg";
import { RECIPE_STATUS, RECIPE_TYPE } from "../constant/constant";

export const RecipeHelper = {
  generateOtherRecipeList: (
    allrecipes: any,
    supplierList: any,
    recipeUuid: string,
    status: number[] = [RECIPE_STATUS.PUBLISHED]
  ): any[] => {
    allrecipes = Object.keys(allrecipes).length === 0 ? [] : allrecipes;
    const otherRecipes = allrecipes.filter(
      (recipe: any) =>
        status.includes(recipe.status) && recipe.uuid !== recipeUuid
    );

    const otherRecipesOptions = _.cloneDeep(otherRecipes).map((recipe: any) => {
      recipe.label = commonService.applyLocalization(
        "restaurant",
        "name",
        recipe.locales
      )["name"];

      if (recipe?.supplier_uuid) {
        const supplierName: string = SupplierHelper.getSupplierDetails(
          recipe.supplier_uuid,
          supplierList
        );
        if (supplierName) recipe.label = recipe.label + ` (${supplierName}) `;
      }
      recipe.value = recipe.uuid;
      recipe.icon = RecipeHelper.getRecipeIcon(recipe.type);
      return recipe;
    });
    return _.sortBy(otherRecipesOptions, "label");
  },

  getRecipeIcon: (iconType: number, color: string = "green"): any => {
    let icon: any = "";

    switch (iconType) {
      case RECIPE_TYPE.RECIPE:
        icon = (
          <img
            alt=""
            src={recipeIcon}
            className={`b-icon-sm ${
              color === "green" ? "b-fill-green" : "b-fill-white"
            }`}
          />
        );
        break;
      case RECIPE_TYPE.INGREDIENT:
        icon = (
          <img
            alt=""
            src={ingredientIcon}
            className={`b-icon-sm ${
              color === "green" ? "b-fill-green" : "b-fill-white"
            }`}
          />
        );
        break;
      case RECIPE_TYPE.CHOICE:
        icon = (
          <FontAwesomeIcon
            className={`mr-1 ${
              color === "green" ? "text-success" : "text-white"
            }`}
            icon={faListOl}
          />
        );

        break;
      case RECIPE_TYPE.DATE_ITEM:
        icon = (
          <FontAwesomeIcon
            className={`mr-1 ${
              color === "green" ? "text-success" : "text-white"
            }`}
            icon={faCalendarDay}
          />
        );

        break;
      case RECIPE_TYPE.INGREDIENT_CLASS:
        icon = (
          <FontAwesomeIcon
            className={`mr-1 ${
              color === "green" ? "text-success" : "text-white"
            }`}
            icon={faSortAmountDown}
          />
        );

        break;
    }

    return icon;
  },

  generateStatusOptions: (
    isRecipe: boolean,
    qualityStatuses: Array<any> = [],
    t: Function
  ): { label: string; value: string }[] => {
    let statusOptions = [
      {
        label: t("label.searchByStatus"),
        value: "",
      },
    ];
    if (!isRecipe) {
      statusOptions.push({
        label: t("recipe.archived"),
        value: RECIPE_STATUS.ARCHIVED.toString(),
      });

      return statusOptions;
    }

    statusOptions.push({
      label: t("recipe.draft"),
      value: RECIPE_STATUS.DRAFT.toString(),
    });
    qualityStatuses.forEach((status) => {
      statusOptions.push({
        label: `${t("recipe.published")} - ${status.name}`,
        value: `${RECIPE_STATUS.PUBLISHED}_${status.uuid}`,
      });
    });
    statusOptions.push({
      label: t("recipe.published"),
      value: RECIPE_STATUS.PUBLISHED.toString(),
    });
    statusOptions.push({
      label: t("recipe.archived"),
      value: RECIPE_STATUS.ARCHIVED.toString(),
    });

    return statusOptions;
  },
};
