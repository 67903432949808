import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import Select from "react-select";
import ReactSlider from "react-slider";
import commonService from "../../../services/common.service";
import "../../../styles/tag-assign.scss";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { USER_RIGHTS } from "../../../constant/constant";
import { recipeSupplierOfferings } from "../../../services/recipe.service";
import { RESTAURANT_REQUEST } from "../../../config/api.config";
import handleResponse from "../../../services/response.service";

export default function OrderModalCard(props: any) {
  const { t, i18n } = useTranslation();
  const [offeredUnit, setOfferedUnit] = useState<string>("");
  const [offeredPrice, setOfferedPrice] = useState<number>(0);
  const [serviceSetRecipe, setServiceSetRecipe] = useState<string>("");
  const [offeredUnitList, setOfferedUnitList] = useState<any>([]);
  const isImported: boolean =
    props.selectedRecipe?.imported_from_id &&
    props.selectedRecipe?.imported_from_id !== "";
  useEffect(() => {
    const offeredUnit =
      props.selectedRecipe?.offered_unit &&
      props.selectedRecipe?.output_unit_uuid !==
        props.selectedRecipe?.offered_unit
        ? props.selectedRecipe?.offered_unit
        : props.selectedRecipe?.output_unit_uuid;
    setOfferedUnit(offeredUnit);
    setOfferedPrice(parseInt(props.selectedRecipe?.price));
    setServiceSetRecipe(props.selectedRecipe?.service_set_recipe);
    if (!props.isSupplierOffering && isImported) {
      buildSupplierOfferingsList();
    }
  }, [props.selectedRecipe, isImported]);

  useEffect(() => {
    handleOfferedUnitChange({ target: { value: offeredUnit } });
  }, [offeredUnitList]);

  const activeRestaurant = JSON.parse(
    localStorage.getItem("ACTIVE_RESTAURANT_DETAILS") || "{}"
  );
  const minorUnit = JSON.parse(
    localStorage.getItem("ACTIVE_RESTAURANT_DETAILS") || "{}"
  )?.minor_units;

  const handleClick = (type: string) => {
    let quantity: number = Number(props?.quantity || "0");

    if (type === "-") {
      if (quantity > 1) quantity--;
      else quantity = 1;
    } else {
      quantity++;
    }

    const payload: any = {
      target: {
        name: "quantity",
        value: String(quantity),
      },
    };
    props.handleChange(payload);
  };

  const buildSupplierOfferingsList = (): void => {
    const payLoad = {
      credentials: {
        recipeuuid: props.selectedRecipe.uuid,
        restaurantuuid: props.restaurantuuid,
      },
    };
    recipeSupplierOfferings(RESTAURANT_REQUEST, payLoad)
      .then((data: any) => {
        const responseData: any = handleResponse(data);
        if (responseData.ok && data?.data.length > 0) {
          setOfferedUnitList(data.data);
        }
      })
      .catch((error: any) => {});
  };

  const handleOfferedUnitChange = (e: any) => {
    let offering = offeredUnitList.find(
      (i: any) => i.offered_unit_uuid === e?.target.value
    );
    if (!offering && offeredUnitList.length > 0) {
      offering = offeredUnitList[0];
    }
    if (offering) {
      setOfferedUnit(offering.offered_unit_uuid);
      setOfferedPrice(parseInt(offering.price));
      setServiceSetRecipe(offering.service_set_recipe_uuid);
    } else {
      setOfferedUnit(props.selectedRecipe.output_unit_uuid);
      setOfferedPrice(parseInt(props.selectedRecipe.price));
      setServiceSetRecipe("");
    }
  };

  const addToShoppingCart = () => {
    props.handleChange(
      {
        target: {
          name: "offered_unit",
          value: offeredUnit,
        },
      },
      true
    );
    props.handleChange(
      {
        target: {
          name: "price",
          value: offeredPrice,
        },
      },
      true
    );
    props.handleChange(
      {
        target: {
          name: "service_set_recipe",
          value: serviceSetRecipe,
        },
      },
      true
    );
    props.handleChange(
      {
        target: {
          name: "unit_name",
          value: props.unitName(offeredUnit),
        },
      },
      true
    );
    props.handleChange(
      {
        target: {
          name: "unit_symbol",
          value: props.unitName(offeredUnit, "symbol"),
        },
      },
      true
    );

    props.saveOrder();
  };

  const renderModifier = (tag: any) => {
    switch (tag?.tag?.meta?.type) {
      case 1: {
        return (
          <div className="position-relative pl-2 mb-3">
            <span className="b-slider-value position-absolute">
              {tag.recipe_tag_meta?.from}
            </span>
            <ReactSlider
              className="b-horizontal-slider"
              defaultValue={tag?.recipe_tag_meta?.default}
              min={tag.recipe_tag_meta?.from}
              max={tag.recipe_tag_meta?.to}
              onChange={(value: any) => {
                props.handleTagChange(tag, value);
              }}
              thumbClassName="b-thumb tm-cursor-p"
              trackClassName="tm-track"
              renderThumb={(props: any, state: any) => (
                <div {...props}>
                  <p className="mt-4 mr-2">
                    {" "}
                    {tag.recipe_tag_meta.value
                      ? tag.recipe_tag_meta.value
                      : tag.recipe_tag_meta.default}
                  </p>
                </div>
              )}
              value={tag.recipe_tag_meta.value}
            />
            <span className="b-slider-value position-absolute">
              {tag.recipe_tag_meta?.to}
            </span>
          </div>
        );
      }
      case 2: {
        return (
          <div
            className="form-check pl-4 ml-1 tm-cursor-p"
            onClick={(e: any) => {
              props.handleTagChange(tag, e.target.checked);
            }}
          >
            <input
              className="form-check-input"
              type="checkbox"
              onChange={(e: any) => {
                props.handleTagChange(tag, e.target.checked);
              }}
              checked={
                tag.recipe_tag_meta?.value !== undefined
                  ? tag.recipe_tag_meta.value
                  : tag.recipe_tag_meta.default
              }
            />
            <label className="form-check-label tm-cursor-p"></label>
          </div>
        );
      }
      case 3: {
        return tag?.recipe_tag_meta?.values?.length > 1 ? (
          <Select
            value={
              !tag.recipe_tag_meta.value
                ? {
                    label: tag.recipe_tag_meta.default,
                    value: tag.recipe_tag_meta.default,
                  }
                : tag.recipe_tag_meta.value
            }
            onChange={(value: any) => {
              props.handleTagChange(tag, value);
            }}
            options={tag.recipe_tag_meta?.values.map((element: any) => ({
              label: element,
              value: element,
            }))}
          />
        ) : tag.key === "com.smarttoni.comment" ? (
          <textarea
            className="form-control ml-2 w-100 tm-textarea"
            value={
              tag.recipe_tag_meta.value !== undefined
                ? tag.recipe_tag_meta.value
                : tag.recipe_tag_meta.values
            }
            onChange={(e: any) => {
              props.handleTagChange(tag, e.target.value);
            }}
          />
        ) : (
          <input
            className="form-control ml-2"
            type="text"
            value={
              tag.recipe_tag_meta.value !== undefined
                ? tag.recipe_tag_meta.value
                : tag.recipe_tag_meta.values
            }
            onChange={(e: any) => {
              props.handleTagChange(tag, e.target.value);
            }}
          />
        );
      }

      default: {
        return <></>;
      }
    }
  };

  return (
    <Modal isOpen={props.isOpen}>
      <ModalHeader toggle={props.toggle} className="modal-header">
        {t("common.order")}
      </ModalHeader>
      <ModalBody>
        <Form className="tag-assign">
          <FormGroup row className="p-0">
            <Label className="text-sm-right" for="tagName" sm={3} lg={3}>
              {t("ingredient.ingredient")}{" "}
            </Label>

            <Col sm={9} lg={9} className="d-flex align-items-center">
              {
                commonService.applyLocalization(
                  "restaurant",
                  "name",
                  props.selectedRecipe?.locales
                )["name"]
              }
              {props.selectedRecipe?.output_quantity && (
                <Badge color="info" className="ml-2 mt-1">
                  <span className="mr-1">
                    {props.selectedRecipe.output_quantity}
                  </span>
                  {props.unitName(offeredUnit, "symbol")}
                </Badge>
              )}
            </Col>
          </FormGroup>
          <FormGroup row className="p-0">
            <Label className="text-sm-right" for="tagName" sm={3} lg={3}>
              {t("common.quantity")}{" "}
              <Label className="required-star-style">*</Label>
            </Label>
            <Col sm={6} lg={6}>
              <Row className="p-0">
                <Col sm={9} md={9} lg={9} className="p-0">
                  <InputGroup className="flex-nowrap">
                    <Button color="info" onClick={() => handleClick("-")}>
                      <FontAwesomeIcon icon={faMinus} />
                    </Button>
                    <Input
                      type="number"
                      required
                      name="quantity"
                      autoComplete="quantity"
                      onChange={props.handleChange}
                      placeholder={t("common.quantity")}
                      value={props.quantity}
                      min="0"
                      className="b-width-75"
                    />
                    <Button color="info" onClick={() => handleClick("+")}>
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                  </InputGroup>
                </Col>
                {!props.isSupplierOffering && isImported ? (
                  <Col sm={3} md={3} lg={3} className="p-0">
                    <Input
                      type="select"
                      id="offeredUnit"
                      required
                      name="offeredUnit"
                      onChange={handleOfferedUnitChange}
                      value={offeredUnit}
                    >
                      {offeredUnitList?.map((option: any, i: number) => {
                        return (
                          <option value={option.offered_unit_uuid} key={i}>
                            {props.unitName(option.offered_unit_uuid, "symbol")}
                          </option>
                        );
                      })}
                    </Input>
                  </Col>
                ) : (
                  <Label sm={3} md={3} lg={3}>
                    {props.unitName(offeredUnit, "symbol")}
                  </Label>
                )}
              </Row>
            </Col>
            {commonService.hasAnyPermission(
              [
                USER_RIGHTS.VIEW_ITEM_PRICES,
                USER_RIGHTS.SUPPLIER_OFFERING_ONLY,
              ],
              activeRestaurant?.uuid
            ) && (
              <Col sm={3} lg={3} className="d-flex align-items-center">
                {parseFloat(
                  commonService.displayDigitalAmount(offeredPrice)
                )?.toFixed(minorUnit)}{" "}
                {activeRestaurant?.currency_code}
              </Col>
            )}
          </FormGroup>
          {props.selectedRecipe?.tags
            ?.filter((tag: any) => tag?.recipe_tag_meta?.override)
            ?.map((tag: any) => (
              <>
                <FormGroup row className="p-0">
                  <Col sm={4} className="text-sm-right">
                    {
                      commonService.applyLocalization(
                        "restaurant",
                        "name",
                        tag?.tag?.locales
                      )["name"]
                    }
                  </Col>
                  <Col sm={8}>{renderModifier(tag)}</Col>
                </FormGroup>
              </>
            ))}

          <FormGroup className="d-flex  b_tree_custom rounded form-group py-2">
            <Col className="d-flex">
              <Label className=" m-0">{t("common.totalQuantity")}</Label>
              <Col>
                <span className="mr-1">
                  {Number(props?.quantity || 0) *
                    props.selectedRecipe.output_quantity}
                </span>
                {props.unitName(offeredUnit, "symbol")}
              </Col>
            </Col>
            {commonService.hasAnyPermission(
              [
                USER_RIGHTS.VIEW_ITEM_PRICES,
                USER_RIGHTS.SUPPLIER_OFFERING_ONLY,
              ],
              activeRestaurant?.uuid
            ) && (
              <Col className="d-flex">
                <Label className=" m-0">{t("common.totalCost")}</Label>
                <Col>
                  <span className="mr-1">
                    {parseFloat(
                      commonService.displayDigitalAmount(
                        Number(props?.quantity || 0) * offeredPrice
                      )
                    )?.toFixed(minorUnit)}{" "}
                  </span>
                  {activeRestaurant?.currency_code}
                </Col>
              </Col>
            )}
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={props.toggle}>
          {t("common.cancel")}
        </Button>
        <Button
          color="info"
          disabled={
            !props.quantity ||
            Number(props.quantity) <= 0 ||
            (isImported && !offeredUnit)
          }
          onClick={addToShoppingCart}
        >
          {t("common.addToShoppingCart")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
