import React, { useCallback, useEffect, useState } from "react";

import { sortBy } from "lodash";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import LoaderComponent from "../loader/loader";
import { BLACK, GREEN } from "../../constant/constant";
import MultiSelectList from "../select/multi-select-list";
import commonService from "../../services/common.service";
import recipeService from "../../services/recipe.service";
import { RESTAURANT_REQUEST } from "../../config/api.config";
import handleResponse from "../../services/response.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RecipeQualityHelper } from "../../helpers/recipeQualityHelper";
import { faEdit, faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  Alert,
  Button,
  Col,
  Form,
  FormGroup,
  Label,
  Progress,
  Row,
} from "reactstrap";

interface ValidationCriteria {
  uuid: string;
  description: string;
  groupName?: string;
}

export default function QualityValidation(props: any) {
  const { t } = useTranslation();

  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [assignedGroups, setAssignedGroups] = useState<Array<any>>([]);
  const [isGroupEdit, setIsGroupEdit] = useState<boolean>(false);
  const [validatedCriterias, setValidatedCriterias] = useState<Array<any>>([]);
  const [allQualityGroups, setAllQualityGroups] = useState<Array<any>>([]);
  const [qualityGroupOptions, setQualityGroupOptions] = useState<Array<any>>(
    []
  );
  const [assignedQualityGroups, setAssignedQualityGroups] = useState<
    Array<any>
  >([]);
  const [validationCriterias, setValidationCriterias] = useState<Array<any>>(
    []
  );

  useEffect(() => {
    if (props.activeTab !== "quality-validation") return;

    RecipeQualityHelper.getGroupList(
      props.restaurantUuid,
      (qualityGroups: any) => {
        setQualityGroupOptions(
          qualityGroups.map((item: any) => ({
            value: item.uuid,
            label: item.name,
            mandatory: item.mandatory,
          }))
        );
        setAllQualityGroups(qualityGroups);
      },
      setIsFetching
    );

    getAssignedQualityGroups();
  }, [props.activeTab, props.restaurantUuid]);

  const setCurrentValidationStatus = () => {
    if (!props?.qualityStatuses) return false;
    let currentStatus = props.qualityStatuses?.find(
      (s: any) => s.uuid === props.itemData?.quality_status_uuid
    );
    if (!currentStatus) return false;
    return {
      ...currentStatus,
      perc: Math.ceil(
        (currentStatus.sort_order * 100) / props.qualityStatuses.length
      ),
    };
  };

  const currentStatus = setCurrentValidationStatus() || {
    name: "",
    perc: 0,
  };

  const buildAssignedGroups = useCallback(
    (assignedQualityGroups: Array<any>) => {
      const mandatoryGroups = qualityGroupOptions?.filter(
        (option) => option.mandatory
      );
      const assignedGroups = assignedQualityGroups?.map((item: any) => ({
        value: item.uuid,
        label: item.name,
        mandatory: item.mandatory,
      }));

      return [...mandatoryGroups, ...assignedGroups].filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.value === value.value)
      );
    },
    [qualityGroupOptions]
  );

  const buildValidationCriterias = useCallback(
    (assignedQualityGroups: Array<any>) => {
      const mandatoryOptions = allQualityGroups?.filter(
        (option) => option.mandatory
      );
      let criterias: Map<string, ValidationCriteria> = new Map();
      [...mandatoryOptions, ...assignedQualityGroups]?.forEach((group) => {
        group?.criterias?.forEach((c: any) => {
          if (c?.assigned_quality_status) {
            criterias.set(c.uuid, {
              groupName: group.name,
              uuid: c.uuid,
              description: commonService.applyLocalization(
                "restaurant",
                "description",
                c?.locales
              )["description"],
            });
          }
        });
      });

      return sortBy(Array.from(criterias.values()), [
        "groupName",
        "description",
      ]);
    },
    [allQualityGroups]
  );

  useEffect(() => {
    setValidationCriterias(buildValidationCriterias(assignedQualityGroups));
    setAssignedGroups(buildAssignedGroups(assignedQualityGroups));
  }, [assignedQualityGroups, buildValidationCriterias, buildAssignedGroups]);

  const handleSelectChange = (value: any, action: any) => {
    value = !value ? [] : value;
    if (action.action === "clear") {
      const mandatoryGroups = qualityGroupOptions?.filter(
        (option) => option.mandatory
      );
      setAssignedGroups(mandatoryGroups);
      return;
    }
    const removedMandatoryItem =
      action.action === "remove-value" &&
      action.removedValue.mandatory === true;
    if (!removedMandatoryItem) setAssignedGroups(value);
  };

  const getAssignedQualityGroups = () => {
    const payLoad = {
      credentials: {
        recipeuuid: props.itemData.uuid,
        restaurantuuid: props.restaurantUuid,
      },
    };
    recipeService
      .getRecipeQualityGroups(RESTAURANT_REQUEST, payLoad)
      .then((data: any) => {
        const assignedQualityGroups = RecipeQualityHelper.setListLocale(
          data.data,
          "name"
        );
        setAssignedQualityGroups(assignedQualityGroups);
      });

    recipeService
      .getRecipeQualityValidations(RESTAURANT_REQUEST, payLoad)
      .then((data: any) => {
        setValidatedCriterias(data?.data?.map((c: any) => c.criteria_uuid));
      });
  };

  const handleGroupSave = () => {
    setIsFetching(true);
    const payLoad = {
      credentials: {
        recipeuuid: props.itemData.uuid,
        restaurantuuid: props.restaurantUuid,
        data: { qualityGroups: assignedGroups?.map((group) => group.value) },
      },
    };
    recipeService
      .storeRecipeQualityGroup(RESTAURANT_REQUEST, payLoad)
      .then((data: any) => {
        handleResponse(data);
        getAssignedQualityGroups();
        setIsGroupEdit(false);
        props.fetchData();
        setIsFetching(false);
      });
  };

  const handleCriteriaValidationSave = () => {
    setIsFetching(true);
    const payLoad = {
      credentials: {
        recipeuuid: props.itemData.uuid,
        restaurantuuid: props.restaurantUuid,
        data: {
          criterias:
            validatedCriterias?.length > 0 ? validatedCriterias : undefined,
        },
      },
    };
    recipeService
      .storeRecipeQualityValidation(RESTAURANT_REQUEST, payLoad)
      .then((data: any) => {
        handleResponse(data);
        getAssignedQualityGroups();
        setIsGroupEdit(false);
        props.fetchData();
        setIsFetching(false);
      });
  };

  return (
    <>
      <LoaderComponent display={isFetching} />
      {currentStatus.perc > 0 && (
        <Progress multi>
          <Progress
            bar
            style={{ backgroundColor: currentStatus?.colour }}
            value={currentStatus.perc}
          >
            {currentStatus.name}
          </Progress>
        </Progress>
      )}
      <div className="child-box mb-3">
        <Form>
          <FormGroup>
            <Row>
              <Col sm="12">
                <Label for="label">{t("recipe.selectQualityGroups")}</Label>
              </Col>
              <Col sm="10">
                <Select
                  value={assignedGroups}
                  onChange={handleSelectChange}
                  options={qualityGroupOptions}
                  isMulti={true}
                  maxMenuHeight={200}
                  placeholder={t("common.select")}
                  isDisabled={!isGroupEdit}
                />
              </Col>
              <Col sm="2">
                {!isGroupEdit && (
                  <Button
                    color="transparent"
                    className="ml-4 p-1"
                    onClick={() => setIsGroupEdit(true)}
                  >
                    <FontAwesomeIcon
                      style={{
                        color: GREEN,
                        fontSize: 18,
                      }}
                      icon={faEdit}
                    />
                  </Button>
                )}
                {isGroupEdit && (
                  <>
                    <Button
                      color="transparent"
                      className="p-1 ml-2"
                      onClick={() => handleGroupSave()}
                    >
                      <FontAwesomeIcon
                        style={{
                          color: GREEN,
                          fontSize: 18,
                        }}
                        icon={faSave}
                      />
                    </Button>
                    <Button
                      color="transparent"
                      className="p-1 ml-3"
                      onClick={() => {
                        getAssignedQualityGroups();
                        setIsGroupEdit(false);
                      }}
                    >
                      <FontAwesomeIcon
                        style={{
                          color: BLACK,
                          fontSize: 18,
                        }}
                        icon={faTimes}
                      />
                    </Button>
                  </>
                )}
              </Col>
            </Row>
          </FormGroup>
        </Form>
      </div>
      {validationCriterias?.length > 0 && (
        <div>
          <Alert className="mt-1" color="primary">
            {t("recipe.qualityValidationInfo")}{" "}
          </Alert>

          <Row className="p-0">
            <Col sm={12}>
              <Label>
                {t("recipe.validateQualityCriteria")}
                <span className="required-star-style">*</span>
              </Label>
              <MultiSelectList
                id={props.itemData.uuid}
                items={validationCriterias}
                initialSelectedUuids={validatedCriterias}
                onSelectionChange={setValidatedCriterias}
                pageSize={20}
              />
            </Col>
          </Row>
          <Row className="justify-content-end p-3">
            <Button
              style={{}}
              color="info"
              onClick={handleCriteriaValidationSave}
            >
              {t("common.save")}
            </Button>
          </Row>
        </div>
      )}
    </>
  );
}
