import { sortBy } from "lodash";
import { Button, Col, Row } from "reactstrap";
import { useParams } from "react-router-dom";
import LoaderComponent from "../loader/loader";
import { useTranslation } from "react-i18next";
import React, { useCallback, useEffect, useState } from "react";
import QualityStatusList from "./quality-status-list";
import commonService from "../../services/common.service";
import handleResponse from "../../services/response.service";
import { RESTAURANT_REQUEST } from "../../config/api.config";
import QualityStatusEditModal from "./quality-status-edit-modal";
import { RecipeQualityHelper } from "../../helpers/recipeQualityHelper";
import DeleteModalCard from "../card-components/delete-card/delete-card";
import recipeQualityStatusService from "../../services/recipe.quality.status.service";
import NavigationRightComponent from "../navigation/navigation-right/navigation-right";
import ConceptHeaderComponent from "../navigation/navigation-header/concept-header/concept-header";
import RestaurantNavComponent from "../navigation/navigation-left/restaurant-navigation/restaurant-navigation";

type ParamTypes = {
  uuid: string;
};

export type QualityStatusFormData = {
  statusUuid: string;
  name: string;
  colour: string;
  sortOrder: number;
  criterias: Array<string>;
};

const defaultFormData = {
  statusUuid: "",
  name: "",
  colour: "#07767c",
  sortOrder: 1,
  criterias: [],
};

const RecipeQualityStatusComponent = () => {
  const { uuid } = useParams<ParamTypes>();
  const { t } = useTranslation();
  const restaurantLang = commonService.getRestaurantDetails()?.languages || [];
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState<boolean>(false);
  const [statusList, setStatusList] = useState<Array<any>>([]);
  const [statusUuid, setStatusUuid] = useState<string>("");
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [recipeQualityCriterias, setRecipeQualityCriterias] = useState<
    Array<any>
  >([]);
  const [filteredCriterias, setFilteredCriterias] = useState<Array<any>>([]);
  const [formData, setFormData] =
    useState<QualityStatusFormData>(defaultFormData);

  const getStatusList = useCallback(() => {
    setIsFetching(true);
    recipeQualityStatusService
      .recipeQualityStatuses(RESTAURANT_REQUEST, {
        restaurantuuid: uuid,
      })
      .then((response: any) => {
        setSortedQualityStatuses(response.data);
        setIsFetching(false);
      });
  }, [uuid]);

  useEffect(() => {
    getStatusList();
    RecipeQualityHelper.getCriteriaList(
      uuid,
      setRecipeQualityCriterias,
      setIsFetching
    );
  }, [uuid, getStatusList]);

  const setSortedQualityStatuses = (groupList: Array<any>) => {
    const items: Array<any> = [];
    groupList?.forEach((item: any) => {
      items.push({
        ...item,
        name: commonService.applyLocalization(
          "restaurant",
          "name",
          item?.locales
        )["name"],
        criterias: RecipeQualityHelper.setListLocale(item?.criterias),
      });
    });
    setStatusList(sortBy(items, "sort_order"));
  };

  const setEditModalToggle = () => {
    setIsEditModalOpen(!isEditModalOpen);
  };

  const getQualityStatusPayload = (
    formData: QualityStatusFormData,
    statusUuid: string = ""
  ) => {
    return {
      restaurantuuid: uuid,
      uuid: statusUuid,
      data: {
        locales: {
          [restaurantLang[0].code]: {
            name: formData.name,
          },
        },
        colour: formData.colour,
        sort_order: formData.sortOrder > 0 ? formData.sortOrder : 1,
        criterias:
          formData.criterias?.length > 0 ? formData.criterias : undefined,
      },
    };
  };

  const editQualityStatus: React.MouseEventHandler<any> = (event) => {
    setIsFetching(true);
    recipeQualityStatusService
      .updateRecipeQualityStatus(
        RESTAURANT_REQUEST,
        getQualityStatusPayload(formData, statusUuid)
      )
      .then((response: any) => {
        handleResponse(response);
        if (response.ok) {
          setEditModalToggle();
          getStatusList();
        }
        setIsFetching(false);
      });
  };

  const initEditQualityStatus = (statusUuid: string) => {
    setStatusUuid(statusUuid);
    const qualityStatus = statusList.find(
      (item, index) => item.uuid === statusUuid
    );
    setFormData({
      statusUuid: statusUuid,
      name: qualityStatus?.name,
      colour: qualityStatus?.colour,
      sortOrder: qualityStatus?.sort_order,
      criterias: qualityStatus?.criterias?.map((c: any) => c.uuid),
    });
    setEditModalToggle();

    buildFilteredCriterias(statusUuid);
  };

  const buildFilteredCriterias = (statusUuid: string = "") => {
    let assignedCriterias: Array<any> = [];
    statusList.forEach((record: any) => {
      if ("" === statusUuid || record.uuid !== statusUuid) {
        record?.criterias?.forEach((criteria: any) => {
          assignedCriterias.push(criteria.uuid);
        });
      }
    });

    setFilteredCriterias(
      recipeQualityCriterias.filter((c) => !assignedCriterias.includes(c.uuid))
    );
  };

  const addRecipeQualityStatus = () => {
    setFormData(defaultFormData);
    setEditModalToggle();
    buildFilteredCriterias();
  };

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const setFormDataCriterias = (criterias: Array<string>) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      criterias: criterias,
    }));
  };

  const saveQualityStatus: React.MouseEventHandler<any> = (event) => {
    setIsFetching(true);
    recipeQualityStatusService
      .storeRecipeQualityStatus(
        RESTAURANT_REQUEST,
        getQualityStatusPayload(formData)
      )
      .then((response: any) => {
        handleResponse(response);
        if (response.ok) {
          setEditModalToggle();
          getStatusList();
        }
        setIsFetching(false);
      });
  };

  const removeModalToggle = () => {
    setIsRemoveModalOpen(!isRemoveModalOpen);
  };

  const initRemoveQualityStatus = (groupUuid: string) => {
    setStatusUuid(groupUuid);
    removeModalToggle();
  };

  const removeQualityStatus: React.MouseEventHandler<any> = (event) => {
    setIsFetching(true);
    const payLoad = {
      restaurantuuid: uuid,
      uuid: statusUuid,
    };
    recipeQualityStatusService
      .removeRecipeQualityStatus(RESTAURANT_REQUEST, payLoad)
      .then((response: any) => {
        handleResponse(response);
        if (response.ok) {
          removeModalToggle();
          getStatusList();
        }
        setIsFetching(false);
      });
  };

  const onStatusSortUpdate = (sortedList: Array<any>) => {
    setIsFetching(true);
    sortedList.forEach((status: any) => {
      const qualityStatus = statusList.find(
        (item: any) => item.uuid === status.uuid
      );
      if (qualityStatus && qualityStatus.sort_order !== status.sortOrder) {
        recipeQualityStatusService
          .updateRecipeQualityStatus(
            RESTAURANT_REQUEST,
            getQualityStatusPayload(
              {
                ...qualityStatus,
                sortOrder: status.sortOrder,
                criterias: qualityStatus?.criterias?.map((c: any) => c.uuid),
              },
              status.uuid
            )
          )
          .then((response: any) => {
            setIsFetching(false);
          });
      }
    });
  };

  return (
    <div>
      <LoaderComponent display={isFetching} />

      <QualityStatusEditModal
        isOpen={isEditModalOpen}
        onCancel={setEditModalToggle}
        onSave={saveQualityStatus}
        onEdit={editQualityStatus}
        handleChange={handleChange}
        formData={formData}
        recipeQualityCriterias={filteredCriterias}
        onCriteriaChange={setFormDataCriterias}
      />

      <DeleteModalCard
        isOpen={isRemoveModalOpen}
        uuid={statusUuid}
        okDelete={removeQualityStatus}
        cancelDelete={removeModalToggle}
      />

      <div className="container-fluid">
        <ConceptHeaderComponent concept={"concept"} />
        <Row className="main light-theme">
          <Col xl={2} lg={3} className="hide-left-max">
            <RestaurantNavComponent
              display={"recipe-quality-status"}
              restaurantuuid={uuid}
            />
          </Col>
          <Col xl={8} lg={6}>
            <div className="white-box mb-3">
              <h4>
                {t("common.recipeQualityStatus")}
                <Button
                  className="ml-2"
                  color="primary"
                  size="sm"
                  onClick={addRecipeQualityStatus}
                >
                  {t("recipe.addRecipeQualityStatus")}
                </Button>
              </h4>
              <QualityStatusList
                restaurantLang={restaurantLang}
                statusList={statusList}
                onEdit={initEditQualityStatus}
                onDelete={initRemoveQualityStatus}
                setStatusList={setStatusList}
                onStatusSortUpdate={onStatusSortUpdate}
              />
            </div>
          </Col>
          <Col xl={2} lg={3}>
            <NavigationRightComponent />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default RecipeQualityStatusComponent;
