import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { Badge, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import {
  RecipeTasksAndIngredientsWrapperProps,
  taskEditType,
} from "../../../models/task.model";
import commonService from "../../../services/common.service";
import { withCardItemSelectionStateManager } from "../../cardItemSelectionManager";
import {
  activatePreparationSet,
  copyPreparationSet,
  getPreparationSets,
  recipeDetails as getNewRecipeDetail,
  removePrepSet,
  setPreparationSets,
  updatePreparationSets,
} from "../../../services/recipe.service";
import RecipeIngredients from "./recipe-ingredients";
import { RESTAURANT_REQUEST } from "../../../config/api.config";
import _ from "lodash";
import { GRAY, RECIPE_STATUS, RECIPE_TYPE } from "../../../constant/constant";
import { faPlusSquare } from "@fortawesome/free-regular-svg-icons";
import { OpenDeleteModal } from "../../card-components/delete-card/delete-modal";
import RecipeTasks from "./recipe-tasks";
import RecipeSelectionModal from "../../recipe-selection/recipe-selection";
import { optionList } from "../../inline-edit-input/inline-edit-input";
import units from "../../units/units";
import { OpenMediaManager } from "../../card-components/media-manager-card/media-manager-card";
import WarningComment from "../../warning-comment/warning-comment";
import RecipePreparationSet from "./recipe-preparation-set";
import recipeIcon from "../../../assets/images/icon/recipeIcon.svg";
import ingredientIcon from "../../../assets/images/icon/ingredientsIcon.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDay,
  faListOl,
  faSortAmountDown,
} from "@fortawesome/free-solid-svg-icons";
import { find } from "ramda";

function RecipeTasksAndIngredientsWrapper(
  props: RecipeTasksAndIngredientsWrapperProps
) {
  const { t, i18n } = useTranslation();
  const selectedRecipesDetails: any = {};
  let editPrepData: any = useRef<any>({});
  let defaultPrepSet: any = useRef<string>("");
  let newPrepSetData: any = useRef<any>({});
  let isFetching = useRef<any>({});
  let ingredientsQuantityData: any = useRef<any>({});
  let ingredientCardSelectionData: any = useRef<any>({});
  const {
    addNewIngredientItem,
    allRecipes,
    baseKitchenUnit,
    getRelatedUnits,
    getSupplierDetails,
    handleIngredientsSave,
    handleIngredientsSorting,
    handlePrepSetChange,
    handleTaskInterventionDelete,
    handleTaskInterventionSave,
    handleTaskItemRemove,
    handleTaskItemSave,
    handleTaskItemUpdate,
    handleTaskStepSave,
    isInterventionUpdated,
    labels,
    machines,
    onItemModify,
    recipeDetails,
    restaurantId,
    restaurantLang,
    showErrorToast,
    showLoader,
    stations,
    units,
    assembleTaskLocales,
  } = props;
  // const [defaultPrepSet, setDefaultPrepSet] = useState<string>("");
  const ingDraftInitState: any = {
    quantity: "",
    type: "newIngredient",
    unitConversion: {},
    unitUuid: null,
    uuid: "newIngredient",
  };
  const [ingredientDraft, setIngredientDraft] = useState(ingDraftInitState);
  const [newPrepSet, setNewPrepSet] = useState("");
  const [calculationQty, setCalculationQty] = useState("");
  const [maintainIngQty, setMaintainIngQty] = useState<boolean>(false);
  const [otherRecipesLists, setOtherRecipeList] = useState<Array<any>>([]);
  const [prepSetEditEnabled, setPrepSetEditEnabled] = useState(false);
  const [preparationSetsOptions, setPreparationSetsOptions] = useState<any>([]);
  const [prepSetCalcUnitsOptions, setPrepSetCalcUnitsOptions] = useState<any>(
    []
  );
  const [prepSetCurrentAction, setPrepSetCurrentAction] = useState<string>("");
  const [quantityWarnings, setQuantityWarnings] = useState<any>({});
  const [recipeIngredients, setRecipeIngredients] = useState<any>([]);
  const [recipeTasks, setRecipeTasks] = useState<any>([]);
  const [selectedCalcUnit, setSelectedCalcUnit] = useState<any>(null);
  const [selectedPrepSet, setSelectedPrepSet] = useState<any>(null);
  const [selectedTaskDetails, setSelectedTaskDetails] = useState<any>({});
  const [taskDraft, setTaskDraft] = useState({
    type: "newTask",
    uuid: "newTask",
  });

  const [taskWithIngDraft, setTaskWithIngDraft] = useState({
    type: "newTaskWithIng",
    uuid: "newTaskWithIng",
    output_quantity: recipeDetails.output_quantity,
  });

  useEffect(() => {
    setTaskWithIngDraft((prev) => ({
      ...prev,
      output_quantity: selectedCalcUnit
        ? calculationQty
        : recipeDetails.output_quantity,
      output_unit_uuid: selectedCalcUnit
        ? selectedCalcUnit.value
        : recipeDetails.output_unit_uuid,
    }));
  }, [recipeDetails, calculationQty, selectedCalcUnit]);
  const [itemDraft, setItemDraft] = useState({});
  useEffect(() => {
    if (recipeDetails?.uuid) {
      const options: Array<any> = getPreparationSetOptions(
        recipeDetails.preparation_sets,
        recipeDetails?.active_preparation_set || ""
      );
      const selectedOption = options.find((option: any) => option.active);
      setSelectedPrepSet(selectedOption);
      setPreparationSetsOptions(options);
      setRecipeRelatedUnitOptions();
    }
  }, [recipeDetails]);

  useEffect(() => {
    if (selectedTaskDetails.type === "newTaskWithIng") {
      //select all rest of the ingredients

      for (const recipeId in quantityWarnings) {
        const qw = quantityWarnings[recipeId];
        if (qw.availableQuantity > 0) {
          handleTaskIngredientSelection("newTaskWithIng", false, recipeId);
        }
      }
    }
  }, [selectedTaskDetails]);

  useEffect(() => {
    if (preparationSetsOptions.length > 0)
      setPrepSetAndRelatedStates(preparationSetsOptions);
  }, [preparationSetsOptions]);

  useEffect(() => {
    if (recipeDetails?.uuid) {
      setRecipeRelatedUnitOptions();
    }
  }, [units]);

  useEffect(() => {
    setCalculationQtyAndUnit();
    resetIngredientsList();
  }, [selectedPrepSet, prepSetCalcUnitsOptions]);

  useEffect(() => {
    resetIngredientsList();
    setIngredientDraft(ingDraftInitState);
  }, [props.ingredients]);

  useEffect(() => {
    resetTasksList();
  }, [props.tasks]);

  // useEffect(() => {

  //   calculateRecipeQuantity();
  // }, [JSON.stringify(recipeIngredients), selectedTaskDetails]);

  useEffect(() => {
    if (allRecipes.length > 0) generateOtherRecipeList();
  }, [recipeIngredients, allRecipes]);

  useEffect(() => {
    if (selectedTaskDetails?.parentTask?.uuid)
      handleTaskSelection(selectedTaskDetails.parentTask.uuid);
    else setSelectedTaskDetails({});
  }, [isInterventionUpdated]);

  useEffect(() => {
    calculateRecipeQuantity();
  }, [props.ingredients, props.tasks, selectedTaskDetails]);

  // TODO need to move out to the root page
  const activatePrepSet = () => {
    showLoader(true);
    const payLoad: any = {
      credentials: {
        recipeUuid: recipeDetails.uuid,
        restaurantUuid: restaurantId,
        prepId: selectedPrepSet?.value,
      },
    };
    activatePreparationSet(RESTAURANT_REQUEST, payLoad)
      .then((response: any) => {
        if (response.status === 200) {
          showLoader(false);
          commonService.toastService(
            t("common.updatedSuccessFully"),
            "success"
          );
          defaultPrepSet.current = selectedPrepSet.value;
          getPrepSetsData();
        } else {
          showLoader(false);
          showErrorToast(response.data.flash);
        }
        props.fetchData();
      })
      .catch(() => {
        showLoader(false);
        commonService.toastService(t("common.updatedFailed"), "danger");
      });
  };

  const addTaskToSortedTasks = (
    tasks: any,
    sortedTasks: any,
    uuid: any,
    recipeEditable: boolean
  ) => {
    if (sortedTasks !== null) {
      let task = tasks.find((taskNode: any) => taskNode.uuid === uuid);
      sortedTasks.unshift(task);
      // Sort interventions
      let interventions: any[] = [];
      let endIntervention = null;

      while (task.interventions?.length > 0) {
        // eslint-disable-next-line
        let tempInterventionIndex: any = task.interventions.findIndex(
          (intervention: any) => {
            if (
              interventions.length === 0 &&
              intervention.intervention_position === 1
            ) {
              return true;
            } else if (
              interventions.length > 0 &&
              intervention.intervention_position === 2 &&
              intervention.intervention_parent_uuid ===
                interventions[interventions.length - 1].uuid
            ) {
              return true;
            } else {
              return false;
            }
          }
        );

        if (tempInterventionIndex > -1) {
          interventions.push(task.interventions[tempInterventionIndex]);
          task.interventions.splice(tempInterventionIndex, 1);
        } else {
          let endIndex = task.interventions.findIndex(
            (intervention: any) => intervention.intervention_position === 3
          );
          if (endIndex > -1) {
            endIntervention = task.interventions.splice(endIndex, 1)[0];
          }
          interventions = interventions.concat(task.interventions);
          task.interventions = [];
        }
      }
      if (
        recipeEditable &&
        (interventions.length === 0 ||
          !interventions[interventions.length - 1].isNewItem)
      ) {
        // interventions.push(this.emptyIntervention());
      }
      if (endIntervention !== null) {
        interventions.push(endIntervention);
      }

      task.interventions = interventions;

      task.interventions.forEach((curIntervention: any) => {
        let taskSteps = curIntervention.task_step
          .slice(0)
          .sort((a: number, b: number) => {
            return a - b;
          });

        if (
          recipeEditable &&
          (taskSteps.length === 0 || !taskSteps[taskSteps.length - 1].isNewItem)
        ) {
          // taskSteps.push(
          //   this.emptyInterventionStep(
          //     task.task_step.length > 0
          //       ? task.task_step[task.task_step.length - 1].position + 1
          //       : 0
          //   )
          // );
        }
        curIntervention.task_step = taskSteps;
      });

      let taskSteps = task.task_step.slice(0).sort((a: number, b: number) => {
        return a - b;
      });

      if (
        recipeEditable &&
        (taskSteps.length === 0 || !taskSteps[taskSteps.length - 1].isNewItem)
      ) {
        // taskSteps.push(
        //   this.emptyTaskStep(
        //     task.task_step.length > 0
        //       ? task.task_step[task.task_step.length - 1].position + 1
        //       : 0
        //   )
        // );
      }
      task.task_step = taskSteps;
    }
  };

  const applyIngredientsSave = (newIngredient?: any) => {
    let allIngredients = recipeIngredients;
    if (newIngredient) allIngredients.push(newIngredient);
    const prepAndRecipeQuantities: any = getPrepAndRecipeQuantities();

    handleIngredientsSave(
      allIngredients,
      selectedPrepSet.value,
      prepAndRecipeQuantities.recipeQtyRatio
    );
  };

  const applyIngredientsSorting = (Ingredients: Array<any>) => {
    const prepAndRecipeQuantities: any = getPrepAndRecipeQuantities();
    handleIngredientsSorting(
      Ingredients,
      selectedPrepSet.value,
      prepAndRecipeQuantities.recipeQtyRatio
    );
  };

  const calculateIngredientQty = (
    taskDetails: any,
    recipeQtyRatio: any,
    ingredientQuantity: any,
    area: string = taskEditType.TASK
  ) => {
    const recipeQuantity: any = _.cloneDeep(ingredientQuantity);
    const recipeUnits: any = {};
    // let ingredientQuantities: any = _.cloneDeep(ingredientQuantity);
    // let remainingQuantity: any = 0;

    taskDetails?.ingredients?.forEach((ing: any) => {
      const recipeUuid: string = ing.recipe_uuid;
      const ingredient: any = _.cloneDeep(ing);

      let totalRecipeQuantity: number = 0;
      let availableQuantity: number = 0;
      let assignedQuantity: number =
        (recipeQuantity[recipeUuid]?.assignedQuantity || 0) *
        (recipeQuantity[recipeUuid]?.unit?.conversionFactor || 1);

      let updatedQty: any =
        calculateRecipeQuantityWithConversionAndCalculationQty(
          ingredient,
          recipeQtyRatio
        );

      recipeUnits[recipeUuid] = recipeUnits[recipeUuid]
        ? recipeUnits[recipeUuid]
        : recipeQuantity[recipeUuid]?.unit
        ? recipeQuantity[recipeUuid].unit
        : ingredientsQuantityData.current?.units[recipeUuid] || {};
      recipeQuantity[recipeUuid] = recipeQuantity[recipeUuid]
        ? recipeQuantity[recipeUuid]
        : {};
      // recipeUnits[recipeUuid] = updatedQty?.unit || {};
      const recipeQtyUnit: any = recipeQuantity[recipeUuid]?.["unit"];
      recipeQuantity[recipeUuid]["warnings"] = {};
      if (
        typeof recipeQuantity[recipeUuid]["totalRecipeQuantity"] === "undefined"
      ) {
        recipeQuantity[recipeUuid]["totalRecipeQuantity"] =
          (updatedQty?.quantity || 0) *
          (updatedQty?.unit?.conversionFactor || 1);
      } else {
        totalRecipeQuantity =
          recipeQuantity[recipeUuid]["totalRecipeQuantity"] *
          (recipeQuantity[recipeUuid]?.unit?.conversionFactor || 1);
      }

      if (
        typeof recipeQuantity[recipeUuid]["availableQuantity"] === "undefined"
      ) {
        recipeQuantity[recipeUuid]["availableQuantity"] =
          (updatedQty?.quantity || 0) *
          (updatedQty?.unit?.conversionFactor || 1);
      } else {
        availableQuantity =
          recipeQuantity[recipeUuid]["availableQuantity"] *
          (recipeQuantity[recipeUuid]?.unit?.conversionFactor || 1);
      }

      availableQuantity =
        availableQuantity -
        (updatedQty?.quantity || 0) * (updatedQty?.unit?.conversionFactor || 1);
      assignedQuantity =
        assignedQuantity +
        (updatedQty?.quantity || 0) * (updatedQty?.unit?.conversionFactor || 1);

      // if (!recipeQuantity[recipeUuid]["unit"])
      recipeQuantity[recipeUuid]["unit"] =
        recipeUnits[recipeUuid] || updatedQty?.unit;
      availableQuantity =
        availableQuantity /
        (recipeQuantity[recipeUuid]?.unit?.conversionFactor || 1);
      assignedQuantity =
        assignedQuantity /
        (recipeQuantity[recipeUuid]?.unit?.conversionFactor || 1);
      totalRecipeQuantity =
        totalRecipeQuantity /
        (recipeQuantity[recipeUuid]?.unit?.conversionFactor || 1);

      recipeQuantity[recipeUuid]["availableQuantity"] =
        commonService.getSignificantDigit(availableQuantity);
      recipeQuantity[recipeUuid]["assignedQuantity"] =
        commonService.getSignificantDigit(assignedQuantity);
      recipeQuantity[recipeUuid]["totalRecipeQuantity"] =
        commonService.getSignificantDigit(totalRecipeQuantity);

      if (commonService.getSignificantDigit(availableQuantity) !== 0) {
        recipeQuantity[recipeUuid]["warnings"] = {
          ...(recipeQuantity[recipeUuid]["warnings"] || {}),
        };

        recipeQuantity[recipeUuid]["warnings"]["taskWarnings"] =
          getWarningsData(availableQuantity, recipeQtyUnit?.symbol || "", area);
      } else {
        delete recipeQuantity["warnings"];
      }

      ingredientsQuantityData.current.units[recipeUuid] =
        recipeQuantity[recipeUuid]["unit"];
    });
    return recipeQuantity;
  };

  const calculateRecipeQuantity = () => {
    let recipeQuantity: any = {};
    let recipeUnits: any = {};
    const prepAndRecipeQuantities: any = getPrepAndRecipeQuantities();

    if (_.size(selectedTaskDetails) > 0) {
      const ingredientQty: any = getIngredientsQuantity(
        props.ingredients,
        prepAndRecipeQuantities.recipeQtyRatio
      );

      recipeQuantity = ingredientQty.recipeQuantity;
      recipeUnits = ingredientQty.recipeUnits;
      if (
        selectedTaskDetails?.type === "newTask" ||
        selectedTaskDetails?.type === "newTaskWithIng" ||
        selectedTaskDetails?.type === taskEditType.TASK
      ) {
        recipeTasks?.tasks.forEach((task: any) => {
          if (task.uuid === selectedTaskDetails.uuid) return;
          const taskDetails: any = _.cloneDeep(task);
          if (taskDetails?.ingredients?.length > 0)
            taskDetails["ingredients"] = getIngredientsWithPreRelatedQty(
              taskDetails["ingredients"],
              "taskSelection"
            );
          recipeQuantity = calculateIngredientQty(
            taskDetails,
            prepAndRecipeQuantities.recipeQtyRatio,
            recipeQuantity
          );
        });
        recipeQuantity = calculateIngredientQty(
          selectedTaskDetails,
          prepAndRecipeQuantities.recipeQtyRatio,
          recipeQuantity
        );

        if (
          !selectedTaskDetails?.isEdit &&
          selectedTaskDetails?.type === taskEditType.TASK
        ) {
          let taskRecipeQuantity: any = {};
          const taskIngQuantity: any = calculateIngredientQty(
            selectedTaskDetails,
            prepAndRecipeQuantities.recipeQtyRatio,
            {}
          );
          Object.keys(taskIngQuantity).forEach((key: string) => {
            taskRecipeQuantity[key] = {
              assignedQuantity: 0,
              availableQuantity:
                (taskIngQuantity[key]?.assignedQuantity || 0) *
                (taskIngQuantity[key]?.unit?.conversionFactor || 1),
              totalRecipeQuantity:
                (taskIngQuantity[key]?.assignedQuantity || 0) *
                (taskIngQuantity[key]?.unit?.conversionFactor || 1),
              unit: taskIngQuantity[key]?.unit,
              warnings: {
                ...(recipeQuantity[key]?.warnings || {}),
                // ...(taskIngQuantity[key]?.warnings || {}),
              },
            };
          });
          recipeQuantity = calculateUsedIngredientsInStepsAndIntervention(
            selectedTaskDetails,
            prepAndRecipeQuantities.recipeQtyRatio,
            taskRecipeQuantity
          );
        }
      } else {
        if (selectedTaskDetails?.parentTask?.uuid) {
          recipeTasks?.tasks.forEach((task: any) => {
            const taskDetails: any = _.cloneDeep(task);
            if (taskDetails?.ingredients?.length > 0)
              taskDetails["ingredients"] = getIngredientsWithPreRelatedQty(
                taskDetails["ingredients"],
                "taskSelection"
              );
            recipeQuantity = calculateIngredientQty(
              taskDetails,
              prepAndRecipeQuantities.recipeQtyRatio,
              recipeQuantity
            );
          });
          const taskIngQuantity: any = calculateIngredientQty(
            selectedTaskDetails.parentTask,
            prepAndRecipeQuantities.recipeQtyRatio,
            {}
          );

          let taskRecipeQuantity: any = {};
          Object.keys(taskIngQuantity).forEach((key: string) => {
            taskRecipeQuantity[key] = {
              assignedQuantity: 0,
              availableQuantity:
                (taskIngQuantity[key]?.assignedQuantity || 0) *
                (taskIngQuantity[key]?.unit?.conversionFactor || 1),
              totalRecipeQuantity:
                (taskIngQuantity[key]?.assignedQuantity || 0) *
                (taskIngQuantity[key]?.unit?.conversionFactor || 1),
              unit: taskIngQuantity[key]?.unit,
              warnings: {
                ...(recipeQuantity[key]?.warnings || {}),
                // ...(taskIngQuantity[key]?.warnings || {}),
              },
            };
          });
          recipeQuantity = calculateUsedIngredientsInStepsAndIntervention(
            selectedTaskDetails.parentTask,
            prepAndRecipeQuantities.recipeQtyRatio,
            taskRecipeQuantity,
            "stepIntervention"
          );

          if (selectedTaskDetails?.isEdit) {
            recipeQuantity = calculateIngredientQty(
              selectedTaskDetails,
              1,
              recipeQuantity,
              "stepIntervention"
            );
          }
          // recipeQuantity = calculateIngredientQty(
          //   selectedTaskDetails,
          //   prepAndRecipeQuantities,
          //   recipeQuantity
          // );
        } else {
          // TODO below function not valid anymore - CLeanup needed
          let taskRecipeQuantity: any = {};

          const taskQtyDtls = calculateIngredientQty(
            selectedTaskDetails,
            prepAndRecipeQuantities.recipeQtyRatio,
            {}
          );

          Object.keys(taskQtyDtls).forEach((key: string) => {
            const assignedQty: number = Number(
              taskQtyDtls[key]?.assignedQuantity || "0"
            );
            taskRecipeQuantity[key] = {
              assignedQuantity: 0,
              availableQuantity: assignedQty,
              totalRecipeQuantity: assignedQty,
            };
          });

          recipeQuantity = calculateUsedIngredientsInStepsAndIntervention(
            selectedTaskDetails,
            prepAndRecipeQuantities.recipeQtyRatio,
            taskRecipeQuantity
          );
        }
      }
    } else if (Array.isArray(props.ingredients) && Array.isArray(props.tasks)) {
      const ingredientQty: any = getIngredientsQuantity(
        props.ingredients,
        prepAndRecipeQuantities.recipeQtyRatio
      );
      recipeQuantity = ingredientQty.recipeQuantity;
      recipeUnits = ingredientQty.recipeUnits;

      _.cloneDeep(props.tasks)?.forEach((task: any) => {
        if (task?.ingredients?.length > 0) {
          task.ingredients.forEach((ing: any) => {
            if (!recipeQuantity[ing.recipe_uuid]) return;
            let recipeUnitConversionFactor: any =
              recipeUnits[ing.recipe_uuid]?.unit?.conversionFactor || 1;
            let quantity: number = ing.quantity;
            if (!ing?.unitConversionDetails) {
              quantity = ing.quantity * prepAndRecipeQuantities.recipeQtyRatio;

              let relatedIngredientUnits: any =
                recipeUnits[ing.recipe_uuid].relatedUnits;
              let ingredientRealUnit: string =
                recipeUnits[ing.recipe_uuid].realUnit;

              const baseKitchenUnitDetails = relatedIngredientUnits?.find(
                (ru: any) => ru.value === baseKitchenUnit
              );
              if (baseKitchenUnitDetails) {
                quantity =
                  quantity / (baseKitchenUnitDetails?.conversionFactor || 1);
                relatedIngredientUnits = getRelatedUnits(
                  baseKitchenUnitDetails.value,
                  ing.recipe_uuid
                );
                ingredientRealUnit = baseKitchenUnitDetails.value;
              }

              const updatedQty: any = commonService.getBestMatchUnitWithQty(
                quantity,
                ingredientRealUnit,
                relatedIngredientUnits
              );
              quantity =
                updatedQty.quantity * (updatedQty?.unit?.conversionFactor || 1);
            } else {
              quantity =
                quantity *
                (ing.unitConversionDetails?.conversionDetails
                  ?.conversionFactor || 1);
            }

            const availableQuantity: number =
              recipeQuantity[ing.recipe_uuid]?.availableQuantity *
              recipeUnitConversionFactor;
            const assignedQuantity: number = quantity;
            const totAssignedQuantity: number =
              recipeQuantity[ing.recipe_uuid]["assignedQuantity"] *
              recipeUnitConversionFactor;
            const remainingQuantity: number =
              (availableQuantity - assignedQuantity) /
              recipeUnitConversionFactor;

            recipeQuantity[ing.recipe_uuid]["availableQuantity"] =
              remainingQuantity;

            recipeQuantity[ing.recipe_uuid]["unit"] =
              recipeUnits[ing.recipe_uuid]?.unit;
            recipeQuantity[ing.recipe_uuid]["assignedQuantity"] =
              (totAssignedQuantity + assignedQuantity) /
              recipeUnitConversionFactor;
            if (remainingQuantity > 0) {
              recipeQuantity[ing.recipe_uuid]["warnings"] = {
                ...(recipeQuantity[ing.recipe_uuid]["warnings"] || {}),
              };
              recipeQuantity[ing.recipe_uuid]["warnings"]["taskWarnings"] =
                getWarningsData(
                  remainingQuantity,
                  recipeUnits[ing.recipe_uuid]?.unit?.symbol
                );
            } else if (recipeQuantity[ing.recipe_uuid]["warnings"]) {
              delete recipeQuantity[ing.recipe_uuid]["warnings"];
            }
          });
        }
      });
    }

    ingredientsQuantityData.current = {
      quantity: _.cloneDeep(recipeQuantity),
      units: recipeUnits,
    };
    setQuantityWarnings(recipeQuantity);
  };

  const calculateRecipeQuantityWithConversionAndCalculationQty = (
    ingredient: any,
    recipeQuantity: any
  ) => {
    let updatedQty: any = { quantity: ingredient?.quantity };
    let unitId: any = ingredient?.realUnit
      ? ingredient.realUnit
      : ingredient?.unitConversionDetails?.realUnit
      ? ingredient?.unitConversionDetails?.realUnit
      : undefined;
    let relatedUnits: Array<any> = [];

    const conversionFactor: any = ingredient?.unitConversion?.conversionFactor
      ? ingredient.unitConversion.conversionFactor
      : ingredient?.unitConversionDetails?.conversionDetails?.conversionFactor
      ? ingredient.unitConversionDetails.conversionDetails?.conversionFactor
      : 1;
    let quantity: number = (ingredient?.quantity || 0) * conversionFactor;
    if (ingredient?.relatedUnits?.length > 0) {
      //  quantity =
      // (ingredient?.quantity || 0) *conversionFactor;
      relatedUnits = ingredient.relatedUnits;
    } else {
      // let relatedUnits: Array<any> = [];
      if (ingredient?.relatedUnits?.length > 0) {
        relatedUnits = ingredient.relatedUnits;
      } else {
        const recipeIngredient: any = recipeIngredients.find(
          (ing: any) => ing.recipeUuid === ingredient.recipe_uuid
        );
        if (!unitId)
          unitId = recipeIngredient?.unitConversionDetails?.realUnit
            ? recipeIngredient?.unitConversionDetails?.realUnit
            : recipeIngredient.unitUuid;

        // if (recipeIngredient?.relatedUnits?.length > 0) {
        //   relatedUnits = recipeIngredient.relatedUnits;
        // } else {
        relatedUnits = getRelatedUnits(unitId, ingredient.recipe_uuid);
        // }
      }
    }

    const relatedIngredientUnits = _.cloneDeep(relatedUnits);
    const baseKitchenUnitDetails = relatedUnits?.find(
      (ru: any) => ru.value === baseKitchenUnit
    );
    if (baseKitchenUnitDetails) {
      quantity = quantity / (baseKitchenUnitDetails?.conversionFactor || 1);
      relatedUnits = getRelatedUnits(
        baseKitchenUnitDetails.value,
        ingredient.recipe_uuid
      );
      unitId = baseKitchenUnitDetails.value;
    }

    updatedQty = commonService.getBestMatchUnitWithQty(
      quantity,
      unitId,
      relatedUnits
    );
    const updatedUnitInitialData: any = relatedIngredientUnits?.find(
      (ru: any) => ru.value === updatedQty?.unit?.value
    );
    updatedQty["unit"] = updatedUnitInitialData;
    return updatedQty;
  };

  const calculateUsedIngredientsInStepsAndIntervention = (
    parentTask: any,
    recipeQtyRatio: any,
    taskRecipeQuantity: any,
    area: string = taskEditType.TASK
  ) => {
    const steps: Array<any> = parentTask?.task_step || [];
    const interventions: Array<any> = parentTask?.interventions || [];
    steps.forEach((step: any) => {
      if (selectedTaskDetails?.isEdit && step.uuid === selectedTaskDetails.uuid)
        return;
      step?.ingredients?.forEach((stepIngredient: any) => {
        const stepIngredientQty: any =
          taskRecipeQuantity[stepIngredient.recipe_uuid];
        const ingQty = commonService.getSignificantDigit(
          (stepIngredient?.quantity || 0) * (recipeQtyRatio || 1)
        );

        stepIngredientQty["assignedQuantity"] += ingQty;
        stepIngredientQty["availableQuantity"] -= ingQty;
      });
    });
    interventions.forEach((intervention: any) => {
      if (
        selectedTaskDetails?.isEdit &&
        intervention.uuid === selectedTaskDetails.uuid
      )
        return;
      intervention?.ingredients?.forEach((interventionIngredient: any) => {
        const interventionIngredientQty: any =
          taskRecipeQuantity[interventionIngredient.recipe_uuid] || {};
        const ingQty = commonService.getSignificantDigit(
          (interventionIngredient?.quantity || 0) * (recipeQtyRatio || 1)
        );
        if (
          typeof interventionIngredientQty["assignedQuantity"] === "undefined"
        )
          interventionIngredientQty["assignedQuantity"] = 0;
        if (
          typeof interventionIngredientQty["availableQuantity"] === "undefined"
        )
          interventionIngredientQty["availableQuantity"] = 0;
        interventionIngredientQty["assignedQuantity"] += ingQty;
        interventionIngredientQty["availableQuantity"] -= ingQty;
      });
    });

    const recipeQty: any = {};
    Object.keys(taskRecipeQuantity)?.forEach((key: any) => {
      const qty: any = taskRecipeQuantity[key];
      if (qty["availableQuantity"] !== 0) {
        if (area === "stepIntervention") {
          qty["warnings"] = {};
        }
        qty["availableQuantity"] = commonService.getSignificantDigit(
          qty["availableQuantity"] / (qty?.unit?.conversionFactor || 1)
        );

        qty["warnings"] = {
          ...qty["warnings"],
          ...{
            stepInterventionWarnings: getWarningsData(
              qty["availableQuantity"],
              qty?.unit?.symbol,
              "stepIntervention"
            ),
          },
        };
      } else if (qty["warnings"]?.["stepInterventionWarning"]) {
        delete qty["warnings"]["stepInterventionWarning"];
      }
      recipeQty[key] = qty;
    });
    return recipeQty;
  };

  const clonePrepSet = () => {
    if (selectedPrepSet?.value) {
      showLoader(true);
      const payload: any = {
        credentials: {
          recipeUuid: recipeDetails.uuid,
          restaurantUuid: restaurantId,
          prepId: selectedPrepSet.value,
        },
      };
      copyPreparationSet(RESTAURANT_REQUEST, payload)
        .then((response) => {
          if (response.status === 200) {
            showLoader(false);
            commonService.toastService(
              t("common.updatedSuccessFully"),
              "success"
            );
            getPrepSetsData();
          } else {
            showLoader(false);
            showErrorToast(response.data.flash);
          }
        })
        .catch((error: any) => {
          showLoader(false);
          commonService.toastService("", "danger", JSON.stringify(error.error));
        });
    }
  };

  const deletePrepSet = () => {
    OpenDeleteModal()
      .then(() => {
        showLoader(true);
        const payLoad: any = {
          credentials: {
            recipeUuid: recipeDetails.uuid,
            restaurantUuid: restaurantId,
            prepId: selectedPrepSet?.value,
          },
        };
        removePrepSet(RESTAURANT_REQUEST, payLoad)
          .then((response: any) => {
            showLoader(false);

            if (response.status === 200) {
              commonService.toastService(
                t("common.removedSuccessFully"),
                "success"
              );
              getPrepSetsData();
            } else {
              commonService.toastService(t("common.removedFailed"), "danger");
            }
          })
          .catch((error: any) => {
            showLoader(false);
            commonService.toastService(
              "",
              "danger",
              JSON.stringify(error.error)
            );
          });
      })
      .catch(() => {});
  };

  const generateOtherRecipeList = () => {
    const ingredients = _.cloneDeep(recipeIngredients)?.map(
      (item: any) => item.recipeUuid
    );
    const otherRecipes = allRecipes.filter(
      (recipe: any) =>
        !ingredients.includes(recipe.uuid) &&
        recipe.status === RECIPE_STATUS.PUBLISHED &&
        recipe.uuid !== recipeDetails.uuid
    );

    const otherRecipesOptions = _.cloneDeep(otherRecipes).map((recipe: any) => {
      recipe.label = commonService.applyLocalization(
        "restaurant",
        "name",
        recipe.locales
      )["name"];

      if (recipe?.supplier_uuid) {
        const supplierName: string = getSupplierDetails(recipe.supplier_uuid);
        if (supplierName) recipe.label = recipe.label + ` (${supplierName}) `;
      }
      recipe.value = recipe.uuid;
      recipe.icon = getRecipeIcon(recipe.type);
      return recipe;
    });
    setOtherRecipeList(_.sortBy(otherRecipesOptions, "label"));
  };

  const getIngredientsQuantity = (
    ingredients: Array<any>,
    recipeQtyRatio: number
  ) => {
    const recipeQuantity: any = {};
    const recipeUnits: any = {};
    ingredients.forEach((ing: any) => {
      // let quantity: number = ing.quantity;

      let quantity: number = ing.quantity * recipeQtyRatio;
      let unitId: string = ing.unitUuid;

      let relatedUnits = getRelatedUnits(ing.unitUuid, ing.recipeUuid);
      let updatedRelateUnits = _.cloneDeep(relatedUnits);
      const baseKitchenUnitDetails = relatedUnits?.find(
        (ru: any) => ru.value === baseKitchenUnit
      );
      if (baseKitchenUnitDetails) {
        quantity = quantity / (baseKitchenUnitDetails?.conversionFactor || 1);
        updatedRelateUnits = getRelatedUnits(
          baseKitchenUnitDetails.value,
          ing.recipeUuid
        );
        unitId = baseKitchenUnitDetails.value;
      }

      const updatedQty: any = commonService.getBestMatchUnitWithQty(
        quantity,
        unitId,
        updatedRelateUnits
      );
      const updatedUnitInitialData: any = relatedUnits?.find(
        (ru: any) => ru.value === updatedQty?.unit?.value
      );

      // const conversionFactor: number = updatedQty?.unit?.conversionFactor || 1;

      recipeUnits[ing.recipeUuid] = updatedQty;
      recipeUnits[ing.recipeUuid]["realUnit"] = ing.unitUuid;
      recipeUnits[ing.recipeUuid]["relatedUnits"] = relatedUnits;

      recipeQuantity[ing.recipeUuid] = {};
      recipeQuantity[ing.recipeUuid]["realUnit"] = ing.unitUuid;
      recipeQuantity[ing.recipeUuid]["warnings"] = {};
      recipeQuantity[ing.recipeUuid]["totalRecipeQuantity"] =
        updatedQty.quantity;
      recipeQuantity[ing.recipeUuid]["availableQuantity"] = updatedQty.quantity;

      recipeQuantity[ing.recipeUuid]["unit"] = updatedUnitInitialData
        ? updatedUnitInitialData
        : updatedQty?.unit;

      recipeQuantity[ing.recipeUuid]["assignedQuantity"] = 0;

      if (quantity > 0) {
        recipeQuantity[ing.recipeUuid]["warnings"] = {
          ...(recipeQuantity[ing.recipeUuid]["warnings"] || {}),
        };
        recipeQuantity[ing.recipeUuid]["warnings"]["taskWarnings"] =
          getWarningsData(updatedQty.quantity, updatedQty?.unit?.symbol);
      } else if (recipeQuantity[ing.recipeUuid]["warnings"]) {
        delete recipeQuantity[ing.recipeUuid]["warnings"];
      }
    });

    return {
      recipeQuantity,
      recipeUnits,
    };
  };

  const getIngredientsWithPreRelatedQty = (
    ingredients: Array<any>,
    area?: string
  ) => {
    const recipeIngredientsData: Array<any> = _.cloneDeep(ingredients);
    const prepAndRecipeQuantities: any = getPrepAndRecipeQuantities();
    recipeIngredientsData.map((ingredient: any) => {
      if (!ingredient?.hasRelatedQuantityUnit) {
        let quantity: number =
          ingredient.quantity * prepAndRecipeQuantities.recipeQtyRatio;
        let updatedQty: any = {};
        let ingredientUnitId: string = ingredient?.unitUuid;
        let ingredientRecipeId: string = ingredient.recipeUuid
          ? ingredient.recipeUuid
          : ingredient.recipe_uuid;
        if (area === "taskSelection") {
          const recipeIngredient: any = recipeIngredients.find(
            (ing: any) => ing.recipeUuid === ingredient.recipe_uuid
          );

          ingredientRecipeId = recipeIngredient?.recipe_uuid
            ? recipeIngredient?.recipe_uuid
            : recipeIngredient?.recipeUuid;
          if (recipeIngredient?.unitConversionDetails?.realUnit)
            ingredientUnitId = recipeIngredient.unitConversionDetails.realUnit;
        }
        let relatedUnits = getRelatedUnits(
          ingredientUnitId,
          ingredientRecipeId
        );
        const realIngredientUnit: string = ingredientUnitId;

        let relatedUnitsWithBaseKitchenUnit = _.cloneDeep(relatedUnits);

        const baseKitchenUnitDetails = relatedUnits?.find(
          (ru: any) => ru.value === baseKitchenUnit
        );
        if (baseKitchenUnitDetails) {
          quantity = quantity / (baseKitchenUnitDetails?.conversionFactor || 1);
          relatedUnitsWithBaseKitchenUnit = getRelatedUnits(
            baseKitchenUnitDetails.value,
            ingredientRecipeId,
            false
          );
          ingredientUnitId = baseKitchenUnitDetails.value;
        }

        updatedQty = commonService.getBestMatchUnitWithQty(
          quantity,
          ingredientUnitId,
          relatedUnitsWithBaseKitchenUnit
        );

        ingredient["hasRelatedQuantityUnit"] = true;
        ingredient["quantity"] = updatedQty.quantity;
        ingredient["relatedUnits"] = relatedUnits;

        if (updatedQty.unit) {
          const unitDtls: any = relatedUnits.find(
            (ru: any) => ru.value === updatedQty.unit.value
          );
          ingredient["unitConversionDetails"] = {
            realUnit: realIngredientUnit,
            conversionDetails: unitDtls,
          };
          ingredient["unitUuid"] = unitDtls.value;
        }
      }
      return ingredient;
    });
    return recipeIngredientsData;
  };

  const getInlineOptions = (optionsArray: Array<any>) => {
    let options: any = [];
    let items = _.map(optionsArray, (item: any) => {
      let label: any = commonService.applyLocalization(
        "restaurant",
        "name",
        item.locales
      )["name"];
      return {
        id: item.uuid,
        name: label,
      };
    });
    return optionList(
      options.concat({ id: "", name: t("common.select") + "..." }).concat(items)
    );
  };

  const getInputReadOnlyLabels = (id: string, area: string, data?: any) => {
    let label: string = "";
    let selectedItem: any;
    let itemData: any;
    switch (area) {
      case "station":
        // itemData = stations?.find((item: any) => item.uuid === id);
        selectedItem = stations;

        break;
      case "machine":
        selectedItem = machines;
        break;
      case "unit":
        const relatedUnits: Array<any> = units.filter((unit: any) => {
          return (
            !unit?.recipe_uuid ||
            (unit?.recipe_uuid && unit.recipe_uuid === recipeDetails.uuid)
          );
        });
        selectedItem = relatedUnits || [];
        break;
    }
    itemData = selectedItem?.find((item: any) => item.uuid === id);
    if (itemData) {
      const labels = commonService.applyLocalization(
        "restaurant",
        "name",
        itemData?.locales
      );
      label = labels["name"];

      if (area === "unit") label = labels["symbol"];
    } else if (area === "machine" && data?.chef_involved)
      label = t("common.cook");
    return label;
  };

  const getMachinesOptions = () => {
    const machineOptions: Array<any> = getInlineOptions(machines);
    const cook: any = optionList([{ id: "cook", name: t("common.cook") }]);
    machineOptions.splice(1, 0, ...cook);
    return machineOptions;
  };

  const getPrepAndRecipeQuantities = () => {
    const recipeOutputQty: number = recipeDetails.output_quantity;
    const prepSetOutputQty: number =
      selectedPrepSet?.calculationQty || recipeDetails.output_quantity;
    const prepSetOutputUnit: string =
      selectedPrepSet?.calculationUnit || recipeDetails.output_unit_uuid;
    const prepSetOutputUnitData: any = prepSetCalcUnitsOptions.find(
      (unit: any) => unit.value === prepSetOutputUnit
    );

    let prepSetOutputQtyWithConversion: number =
      prepSetOutputQty * (prepSetOutputUnitData?.conversionFactor || 1);
    const recipeQtyRatio: number =
      prepSetOutputQtyWithConversion / recipeOutputQty;

    if (!prepSetOutputQtyWithConversion) prepSetOutputQtyWithConversion = 1;
    return {
      recipeOutputQty,
      prepSetOutputQtyWithConversion,
      recipeQtyRatio,
    };
  };

  const getPreparationSetOptions = (
    prepSets: Array<any>,
    defaultPrep: string,
    isItemModified?: boolean
  ) => {
    if (!prepSets) return [];
    const options: Array<any> = prepSets.map((prepSet: any) => {
      let label: any = commonService.applyLocalization(
        "restaurant",
        "name",
        prepSet.locales
      )["name"];
      const active: boolean = prepSet?.active
        ? prepSet.active == 1
        : defaultPrep === prepSet.uuid;
      return {
        active: active,
        calculationQty: prepSet?.calculationOutputQuantity || "",
        calculationUnit: prepSet?.calculationOutputUnit || "",
        label: label,
        value: prepSet.uuid,
      };
    });
    return _.sortBy(options, "label");
  };

  const getPrepSetsData = () => {
    const payLoad = {
      credentials: {
        recipeUuid: recipeDetails.uuid,
        restaurantUuid: restaurantId,
      },
    };
    getPreparationSets(RESTAURANT_REQUEST, payLoad).then((response) => {
      if (response.status === 200) {
        const options: Array<any> = getPreparationSetOptions(
          response.data,
          defaultPrepSet.current
        );
        setPreparationSetsOptions(options);
      }
    });
  };

  const getQuantityRelatedDetails = (
    recipeId: string,
    quantity: number,
    unitId: string
  ) => {
    let relatedUnits = getRelatedUnits(unitId, recipeId);

    const relatedIngredientUnits = _.cloneDeep(relatedUnits);

    const baseKitchenUnitDetails = relatedIngredientUnits?.find(
      (ru: any) => ru.value === baseKitchenUnit
    );
    if (baseKitchenUnitDetails) {
      quantity = quantity / (baseKitchenUnitDetails?.conversionFactor || 1);
      relatedUnits = getRelatedUnits(baseKitchenUnitDetails.value, recipeId);
      unitId = baseKitchenUnitDetails.value;
    }

    const updatedQty: any = commonService.getBestMatchUnitWithQty(
      quantity,
      unitId,
      relatedIngredientUnits
    );

    const updatedUnitInitialData: any = relatedUnits?.find(
      (ru: any) => ru.value === updatedQty?.unit?.value
    );
    updatedQty["unit"] = updatedUnitInitialData;

    return updatedQty;
  };

  const getRecipeDetails = (recipeId: string) => {
    return allRecipes?.find((recipe: any) => recipe.uuid === recipeId);
  };

  const getRecipeDetailsAsync = (recipeId: string) => {
    if (selectedRecipesDetails[recipeId])
      return selectedRecipesDetails[recipeId];
    else if (!isFetching.current[recipeId]) {
      const payLoad = {
        credentials: {
          recipeuuid: recipeId,
          restaurantuuid: restaurantId,
        },
      };
      isFetching.current[recipeId] = true;
      return getNewRecipeDetail(RESTAURANT_REQUEST, payLoad).then(
        (response: any) => {
          if (response.status === 200) {
            selectedRecipesDetails[recipeId] = response.data;
            isFetching.current[recipeId] = false;
            return response.data;
          }
        }
      );
    }

    // return allRecipes?.find((recipe: any) => recipe.uuid === recipeId);
  };

  const getRecipeIcon = (iconType: number, color: string = "green") => {
    let icon: any = "";

    switch (iconType) {
      case RECIPE_TYPE.RECIPE:
        icon = (
          <img
            src={recipeIcon}
            className={`b-icon-sm ${
              color === "green" ? "b-fill-green" : "b-fill-white"
            }`}
          />
        );
        break;
      case RECIPE_TYPE.INGREDIENT:
        icon = (
          <img
            src={ingredientIcon}
            className={`b-icon-sm ${
              color === "green" ? "b-fill-green" : "b-fill-white"
            }`}
          />
        );
        break;
      case RECIPE_TYPE.CHOICE:
        icon = (
          <FontAwesomeIcon
            className={`mr-1 ${
              color === "green" ? "text-success" : "text-white"
            }`}
            icon={faListOl}
          />
        );

        break;
      case RECIPE_TYPE.DATE_ITEM:
        icon = (
          <FontAwesomeIcon
            className={`mr-1 ${
              color === "green" ? "text-success" : "text-white"
            }`}
            icon={faCalendarDay}
          />
        );

        break;
      case RECIPE_TYPE.INGREDIENT_CLASS:
        icon = (
          <FontAwesomeIcon
            className={`mr-1 ${
              color === "green" ? "text-success" : "text-white"
            }`}
            icon={faSortAmountDown}
          />
        );

        break;
    }

    return icon;
  };

  const getRelatedUnitOptions = (outputUnitId: string, recipeId: string) => {
    // const units: Array<any> = getUnitDropdowns(outputUnitId, recipeId);
    const recipeUnitOptions: Array<any> = getRelatedUnits(
      outputUnitId,
      recipeId
    );

    return recipeUnitOptions;
  };

  const getStationsOptions = () => {
    return getInlineOptions(stations);
  };

  const getTaskMapCopy = (taskMap: any) => {
    let tempMap = new Map();
    taskMap.forEach((task: any, uuid: any) => {
      tempMap.set(uuid, {
        parent: task.parent?.slice(0),
        children: task.children,
        currentCount: task.children,
        sorted: false,
        task: task.task,
      });
    });
    return tempMap;
  };

  const getTasksPreparedToDisplay = (
    orig_tasks: any[],
    recipeEditable: boolean
  ) => {
    let tasks = orig_tasks.slice(0) || [];

    if (recipeEditable) {
      // tasks.unshift(this.emptyTask());
    }
    const taskMap: any = new Map();
    const prepAndRecipeQuantities: any = getPrepAndRecipeQuantities();
    tasks?.forEach((task: any) => {
      task["output_quantity"] =
        (task?.output_quantity || 0) *
        (prepAndRecipeQuantities?.recipeQtyRatio || 1);
      if (_.size(task.dependent_tasks) > 0) {
        task.dependent_tasks?.forEach((d_task: any) => {
          const parentTask: any = tasks.find(
            (tsk: any) => tsk.uuid === d_task.task_uuid
          );
          if (parentTask?.children) parentTask.children.push(task.uuid);
          else parentTask["children"] = [task.uuid];
        });
      }

      taskMap.set(task.uuid, {
        parent: task.dependent_tasks?.map((depTask: any) => {
          return depTask.task_uuid;
        }),
        children: 0,
        task: task,
      });
      let isLastTask = false;
      if (tasks.length === 1) {
        isLastTask = true;
      } else {
        const currentTaskId = task.uuid;
        //check if this uuid present in dependent_tasks of other tasks
        const taskWithCurrentAsDependent = find((t) => {
          const dependent = t.dependent_tasks;
          const f = find((d) => d.task_uuid === currentTaskId, dependent);
          return !!f;
        }, tasks);
        isLastTask = !taskWithCurrentAsDependent;
      }
      task.isLastTask = isLastTask;
      if (isLastTask) {
        let relatedUnits = getRelatedUnits(
          task.output_unit_uuid,
          recipeDetails.uuid
        );
        const calcUnitFromRelated = find((ru) => {
          return (
            ru.value ===
            (selectedCalcUnit?.value || recipeDetails.output_unit_uuid)
          );
        }, relatedUnits);
        if (calcUnitFromRelated) {
          // convert task quantity to calculation quantity
          const taskQtyInCalculationUnit =
            task.output_quantity / calcUnitFromRelated.conversionFactor;
          // check if calculation qty and last task quantity is supplierName
          if (
            commonService.getSignificantDigit(taskQtyInCalculationUnit) !==
            commonService.getSignificantDigit(
              calculationQty || recipeDetails.output_quantity
            )
          ) {
            task.showLastTaskQuantityWarning = true;
          }
        } else {
          task.showLastTaskQuantityWarning = true;
        }
      }
    });
    // TODO  Need to rewrite it!
    tasks?.forEach((task: any) => {
      task.dependent_tasks?.forEach((parent: any) => {
        taskMap.get(parent.task_uuid).children++;
      });
    });

    let sortedTasks: any = [];
    return sortTasks(
      tasks,
      getTaskMapCopy(taskMap),
      sortedTasks,
      recipeEditable
    );
  };

  const getTotalRecipeIngredientQuantity = (
    ingredients: Array<any>,
    area: string,
    areaId: string,
    recipeQuantity: any
  ) => {
    const prepAndRecipeQuantities: any = getPrepAndRecipeQuantities();
    const recipeIngredientsData = ingredients.map((ingredient: any) => {
      const recipeIngredient: any = recipeIngredients.find(
        (ing: any) => ing.recipeUuid === ingredient.recipe_uuid
      );

      const recipeIngredientConversionFactor: number = recipeIngredient
        ?.unitConversion?.conversionFactor
        ? recipeIngredient.unitConversion.conversionFactor
        : recipeIngredient?.unitConversionDetails?.conversionDetails
            ?.conversionFactor
        ? recipeIngredient.unitConversionDetails.conversionDetails
            .conversionFactor
        : 1;

      const ingredientConversionFactor: any = ingredient?.unitConversion
        ?.conversionFactor
        ? ingredient.unitConversion.conversionFactor
        : ingredient?.unitConversionDetails?.conversionDetails?.conversionFactor
        ? ingredient.unitConversionDetails.conversionDetails.conversionFactor
        : recipeIngredientConversionFactor;

      if (recipeQuantity[ingredient.recipe_uuid]["availableQuantity"] < 0)
        ingredient["recipe_quantity"] =
          -recipeQuantity[ingredient.recipe_uuid]["availableQuantity"] +
          recipeQuantity[ingredient.recipe_uuid]["totalRecipeQuantity"];

      ingredient["quantity"] =
        (ingredient["quantity"] * ingredientConversionFactor) /
        prepAndRecipeQuantities.recipeQtyRatio;
      if (ingredient["recipe_quantity"])
        ingredient["recipe_quantity"] =
          (ingredient["recipe_quantity"] * ingredientConversionFactor) /
          prepAndRecipeQuantities.recipeQtyRatio;

      delete ingredient?.hasRelatedQuantityUnit;
      delete ingredient?.relatedUnits;
      delete ingredient?.realUnit;
      delete ingredient?.unitConversion;
      delete ingredient?.unitConversionDetails;

      return ingredient;
    });

    return recipeIngredientsData;
  };

  const getUnitDetails = (unitId: string) => {
    const selectedUnit: any = units?.find((unit: any) => unit.uuid === unitId);
    if (selectedUnit) {
      selectedUnit.labels = commonService.applyLocalization(
        "restaurant",
        "name",
        selectedUnit.locales
      );
    }
    return selectedUnit;
  };

  const getUnitOptions = () => {
    const selectedUnits = units.filter((unit: any) => {
      if (unit?.recipe_uuid) {
        return unit.recipe_uuid === recipeDetails.uuid;
      } else return true;
    });
    return getInlineOptions(selectedUnits);
  };

  const getUuidFromMediaManager = async (
    defaultUuid: any = null,
    filter: any = [],
    entityType: string = "media"
  ) => {
    return await OpenMediaManager({
      restaurantUuid: restaurantId,
      typeFilter: filter,
      predefinedMediaUuid: defaultUuid,
      apiFilters: {
        pageNo: 1,
        pageSize: 50,
        filter: "type=" + filter,
      },
      entityType: entityType,
    });
  };

  const getWarningsData = (
    qty: any,
    unitSymbol: string,
    area: string = taskEditType.TASK
  ) => {
    let type: string = "attention";
    let text: string =
      qty && qty < 0
        ? t("warning.willBeAssignedRecipe")
        : t("warning.notAssignedTasks");

    if (_.size(selectedTaskDetails) > 0) {
      // if (
      //   selectedTaskDetails?.type === taskEditType.STEP ||
      //   selectedTaskDetails?.type === taskEditType.INTERVENTION
      // ) {
      //   text =
      //     qty && qty < 0
      //       ? t("warning.willBeAssignedTask")
      //       : t("warning.notAssignedInterventionsSteps");
      // } else
    }
    if (area === "stepIntervention") {
      text =
        qty && qty < 0
          ? t("warning.willBeAssignedTask")
          : t("warning.itemNotAssignedInterventionsSteps");
    }
    const qtyText: number = qty < 0 ? -qty : qty;
    return {
      notAssigned: {
        type: type,
        text:
          commonService.getSignificantDigit(qtyText) + ` ${unitSymbol} ` + text,
      },
    };
  };

  const handleChange = (element: any) => {
    if (element.target.name === "prepSetName") {
      setNewPrepSet(element.target.value);
    } else if (element.target.name === "calculationQty") {
      setCalculationQty(element.target.value);
    } else if (element.target.name === "maintainIngQty") {
      setMaintainIngQty(!maintainIngQty);
    }
  };

  const handleDeleteIngredient = (ingredientId: string) => {
    const updatedIngredients = recipeIngredients?.filter(
      (ing: any) => ing.uuid !== ingredientId
    );
    const prepAndRecipeQuantities: any = getPrepAndRecipeQuantities();
    handleIngredientsSave(
      updatedIngredients,
      selectedPrepSet.value,
      prepAndRecipeQuantities.recipeQtyRatio
    );
  };

  function handleFormChanges(data: any, field: any, value: any) {
    switch (field) {
      case "outputQuantity":
        data["quantity"] = value;
        break;
      case "defaultChoice":
        data["default_item"] = value;
        break;
      case "defaultQty":
        data["default_choice_count"] = value;
        break;
      case "minQty":
        data["min_choice_count"] = value;
        break;
      case "maxQty":
        data["max_choice_count"] = value;
        break;
    }
  }

  const handleIngredientEdit = (name: string, value: any) => {
    const fieldName = name.split("_");
    const ingredientIndx: any = recipeIngredients.findIndex(
      (ing: any) => ing.uuid === fieldName[1]
    );

    if (fieldName[0] === "outputQuantity" && value < 0) return;
    if (selectedTaskDetails?.isEdit) {
      setSelectedTaskDetails((prevState: any) => {
        const data: any = { ...prevState };
        const ingredient = data?.ingredients?.find(
          (ing: any) => ing.recipe_uuid === fieldName[2]
        );
        if (ingredient) handleFormChanges(ingredient, fieldName[0], value);
        return data;
      });
    } else if (fieldName[1] === "newIngredient") {
      const ingDraft: any = _.cloneDeep(ingredientDraft);
      if (ingDraft) handleFormChanges(ingDraft, fieldName[0], value);

      setIngredientDraft(ingDraft);
    } else if (ingredientIndx > -1) {
      const ingredients: any = _.cloneDeep(recipeIngredients);
      if (ingredients?.[ingredientIndx])
        handleFormChanges(ingredients[ingredientIndx], fieldName[0], value);
      setRecipeIngredients(ingredients);
    }
  };

  const handleIngredientEditCancel = () => {
    resetIngredientsList();
  };

  const handleOutputUnitSelection = (
    recipe_uuid: string,
    selection: any,
    itemId: string
  ) => {
    if (selectedTaskDetails?.isEdit) {
      setSelectedTaskDetails((prevState: any) => {
        const data: any = { ...prevState };
        const ingredient = data?.ingredients?.find(
          (ing: any) => ing.recipe_uuid === recipe_uuid
        );

        if (ingredient) {
          if (!ingredient?.["realUnit"]) {
            const ingDetails: any = recipeIngredients?.find(
              (ing: any) => ing?.recipeUuid === ingredient?.recipe_uuid
            );
            ingredient["realUnit"] = ingDetails?.unitConversionDetails?.realUnit
              ? ingDetails?.unitConversionDetails?.realUnit
              : ingDetails?.unitUuid;
          }

          ingredient["unitConversion"] = selection;
          ingredient["unitUuid"] = selection.value;
        }
        return data;
      });
    }

    if (itemId === "newIngredient") {
      setIngredientDraft((prevState: any) => {
        const update: any = { ...prevState };
        update["unitConversion"] = selection;
        update["unitUuid"] = selection.value;
        if (!update["recipeUnitUuid"])
          update["recipeUnitUuid"] = prevState.unitUuid;
        return update;
      });
    } else {
      const ingredientIndx: any = recipeIngredients.findIndex(
        (ing: any) => ing.uuid === itemId
      );
      if (ingredientIndx > -1) {
        setRecipeIngredients((prevState: any) => {
          const update = [...prevState];
          const item: any = update[ingredientIndx];
          if (!item["recipeUnitUuid"]) item["recipeUnitUuid"] = item.unitUuid;
          item["unitConversion"] = selection;
          item["unitUuid"] = selection.value;
          return update;
        });
      }
    }
  };

  const handlePrepSetCalcUnit = (selection: any) => {
    setSelectedCalcUnit(selection);
  };

  const handlePrepSetSelection = (selection: any) => {
    setSelectedPrepSet(selection);
    handlePrepSetChange(selection);
  };

  const handleTaskIngredientSelection = (
    cardIdWithParent: string,
    isActive: boolean,
    recipeId: string
  ) => {
    const cardId: any = cardIdWithParent.split("_")[0];
    if (cardId === "newTask") {
      // setTaskDraft((prevState: any) => {
      //   const update: any = { ...prevState };
      //   update.isDraftEdit = true;
      //   return update;
      // });
    } else if (cardId === "newIntervention" || cardId === "newStep") {
      // setItemDraft((prevState: any) => {
      //   const update: any = { ...prevState };
      //   update.isDraftEdit = true;
      //   return update;
      // });
    } else {
      setRecipeTasks((PrevState: any) => {
        const data = { ...PrevState };

        const activeTask: any = data.tasks?.find(
          (task: any) => task.uuid === selectedTaskDetails.uuid
        );

        if (_.size(activeTask?.ingredients) > 0) {
          const existingDependencies: Array<any> =
            activeTask.ingredients?.filter(
              (tsk: any) => tsk.recipe_uuid === recipeId
            );
          if (existingDependencies.length > 0) {
            activeTask.ingredients = activeTask.dependent_tasks.filter(
              (tsk: any) => tsk.recipe_uuid !== recipeId
            );
          } else {
            activeTask.ingredients.push({ recipe_uuid: recipeId });
          }
        } else if (activeTask) {
          activeTask["ingredients"] = [{ recipe_uuid: recipeId }];
        }
        return data;
      });
    }
    setSelectedTaskDetails((prevState: any) => {
      const data: any = { ...prevState };
      if (isActive) {
        data["ingredients"] = data.ingredients?.filter(
          (dTask: any) => dTask.recipe_uuid !== recipeId
        );
      } else {
        data["ingredients"] = data?.ingredients || [];
        const ingsArray = data["ingredients"].map((ing) => ing.recipe_uuid);
        if (!ingsArray.includes(recipeId)) {
          data.ingredients.push({ recipe_uuid: recipeId });
        }
      }
      return data;
    });
  };

  const handleTaskDependencySelection = (
    selectedTaskId: string,
    isActive: boolean
  ) => {
    setRecipeTasks((PrevState: any) => {
      const data = { ...PrevState };
      // TODO need to cleanup duplicate
      let activeTask: any = {};
      const isItSubItem: boolean = _.size(selectedTaskDetails?.parentTask) > 0;
      let activeItem: any = {};
      if (isItSubItem) {
        activeTask = data.tasks?.find(
          (task: any) => task.uuid === selectedTaskDetails.parentTask?.uuid
        );

        // if (selectedTaskDetails.type === taskEditType.INTERVENTION) {
        //   activeItem =
        //     activeTask?.interventions?.find(
        //       (int: any) => int.uuid === selectedTaskDetails.uuid
        //     ) || {};

        // }
      } else {
        if (selectedTaskDetails.uuid !== "newTask") {
          activeTask = data.tasks?.find(
            (task: any) => task.uuid === selectedTaskDetails.uuid
          );
          activeItem = activeTask;
        }
      }

      if (_.size(activeItem?.dependent_tasks) > 0) {
        const existingDependencies: Array<any> =
          activeItem.dependent_tasks?.filter(
            (tsk: any) => tsk.task_uuid === selectedTaskId
          );
        if (existingDependencies.length > 0)
          activeItem.dependent_tasks = activeItem.dependent_tasks.filter(
            (tsk: any) => tsk.task_uuid !== selectedTaskId
          );
        else activeItem.dependent_tasks.push({ task_uuid: selectedTaskId });
      } else if (_.size(activeItem) > 0) {
        activeItem["dependent_tasks"] = [{ task_uuid: selectedTaskId }];
      }

      return data;
    });

    setSelectedTaskDetails((prevState: any) => {
      const data: any = { ...prevState };
      if (isActive) {
        data["dependent_tasks"] = data.dependent_tasks?.filter(
          (dTask: any) => dTask.task_uuid !== selectedTaskId
        );
      } else {
        data["dependent_tasks"] = data?.dependent_tasks || [];
        data.dependent_tasks.push({ task_uuid: selectedTaskId });
      }
      return data;
    });
  };

  const handleTaskSelection = (
    taskId: string,
    area?: string,
    itemData?: any
  ) => {
    if (Object.keys(ingredientCardSelectionData.current).length > 0) {
      ingredientCardSelectionData.current.setSelectedCardItem([], "default");
      handleIngredientEditCancel();
      ingredientCardSelectionData.current = {};
    }
    let taskDetails: any = {};
    taskDetails = _.cloneDeep(props.tasks).find(
      (task: any) => task.uuid === taskId
    );
    if (area) {
      let itemDetails: any;
      if (
        area === taskEditType.INTERVENTION ||
        area === taskEditType.INTERVENTION_STEP
      ) {
        itemDetails = _.cloneDeep(taskDetails?.interventions)?.find(
          (int: any) => int.uuid === itemData?.interventionId
        );
        if (itemDetails && area === taskEditType.INTERVENTION_STEP) {
          const parentIntervention: any = _.cloneDeep(itemDetails);

          itemDetails = _.cloneDeep(itemDetails?.task_step)?.find(
            (int: any) => int.uuid === itemData?.interventionStepId
          );
          if (itemDetails)
            itemDetails["parentIntervention"] = parentIntervention;
        }
      } else if (area === taskEditType.STEP) {
        itemDetails = _.cloneDeep(taskDetails?.task_step)?.find(
          (int: any) => int.uuid === itemData?.itemId
        );
      }
      if (itemDetails) {
        const parentTaskDetails = _.cloneDeep(taskDetails);
        if (parentTaskDetails?.ingredients?.length > 0)
          parentTaskDetails["ingredients"] = getIngredientsWithPreRelatedQty(
            parentTaskDetails["ingredients"],
            "taskSelection"
          );
        taskDetails = itemDetails;
        taskDetails["dependent_tasks"] = itemDetails?.prepared_items || [];
        taskDetails["parentTask"] = parentTaskDetails;
        taskDetails["type"] = area;
      }
    }
    if (_.size(taskDetails) > 0)
      taskDetails["selectedPrepSet"] = selectedPrepSet;
    if (taskDetails?.ingredients?.length > 0)
      taskDetails["ingredients"] = getIngredientsWithPreRelatedQty(
        taskDetails["ingredients"],
        "taskSelection"
      );
    if (!taskDetails?.type) taskDetails["type"] = taskEditType.TASK;

    setSelectedTaskDetails(taskDetails || {});
  };

  const onCancelTaskEdit = () => {
    resetTasksList();
  };

  const onDeleteTaskItem = (taskId: string) => {
    handleTaskItemRemove(taskId, selectedPrepSet.value);
  };

  const onEditIngredients = (cardData: any) => {
    ingredientCardSelectionData.current = cardData;
    setSelectedTaskDetails({});
  };

  const onSaveTaskItem = (
    formData: any,
    isNewCardItem: boolean,
    task_uuid: string
  ) => {
    const payload: any = {
      ...formData,
      ...{ preparation_set: selectedPrepSet.value },
    };
    let recipeQuantity: any = {};
    const prepAndRecipeQuantities: any = getPrepAndRecipeQuantities();

    const ingredientQty: any = getIngredientsQuantity(
      props.ingredients,
      prepAndRecipeQuantities.recipeQtyRatio
    );
    recipeQuantity = ingredientQty.recipeQuantity;
    recipeTasks?.tasks.forEach((task: any) => {
      if (task.uuid === selectedTaskDetails.uuid) return;
      const taskDetails: any = _.cloneDeep(task);

      if (taskDetails?.ingredients?.length > 0)
        taskDetails["ingredients"] = getIngredientsWithPreRelatedQty(
          taskDetails["ingredients"],
          "taskSelection"
        );
      recipeQuantity = calculateIngredientQty(taskDetails, 1, recipeQuantity);
    });

    recipeQuantity = calculateIngredientQty(
      selectedTaskDetails,
      1,
      recipeQuantity
    );
    if (isNewCardItem) {
      if (selectedTaskDetails?.ingredients) {
        const ingredients = _.cloneDeep(selectedTaskDetails.ingredients).map(
          (ing: any) => getUpdatedIngredientData(ing)
        );
        const ings = getTotalRecipeIngredientQuantity(
          ingredients || [],
          taskEditType.TASK,
          task_uuid,
          recipeQuantity
        );
        payload["ingredients"] = ings;
      }
      let parent_task: any = [];
      if (selectedTaskDetails.parent_task)
        parent_task = selectedTaskDetails.parent_task;
      else if (_.size(selectedTaskDetails.dependent_tasks) > 0) {
        parent_task = selectedTaskDetails.dependent_tasks.map(
          (tsk: any) => tsk.task_uuid
        );
      }

      if (
        selectedTaskDetails.type === "newTaskWithIng" &&
        !selectedTaskDetails.parent_task &&
        _.size(selectedTaskDetails.dependent_tasks) === 0
      ) {
        const tasks = recipeTasks.tasks;
        const noOfTasks = tasks.length;
        if (tasks) {
          parent_task = tasks.length > 0 ? [tasks[noOfTasks - 1].uuid] : [];
        }
      }

      payload["parent_task"] = parent_task;
      payload["output_quantity"] =
        payload["output_quantity"] /
        (prepAndRecipeQuantities?.recipeQtyRatio || 1);
      setTaskDraft((prevState: any) => {
        const update: any = { ...prevState };
        update.isDraftEdit = false;

        return update;
      });
      handleTaskItemSave(payload);
    } else {
      const activeTask: any = recipeTasks?.tasks?.find(
        (tsk: any) => tsk.uuid === task_uuid
      );
      if (_.size(activeTask)) {
        let parent_task: any = [];
        if (activeTask.parent_task) parent_task = activeTask.parent_task;
        else if (_.size(activeTask.dependent_tasks) > 0) {
          parent_task = activeTask.dependent_tasks.map(
            (tsk: any) => tsk.task_uuid
          );
        }
        payload["parent_task"] = parent_task;
        if (selectedTaskDetails?.ingredients) {
          const ingredients = _.cloneDeep(selectedTaskDetails.ingredients).map(
            (ing: any) => getUpdatedIngredientData(ing)
          );
          payload["ingredients"] = getTotalRecipeIngredientQuantity(
            ingredients || [],
            taskEditType.TASK,
            task_uuid,
            recipeQuantity
          );
        } else if (activeTask?.ingredients)
          payload["ingredients"] = getTotalRecipeIngredientQuantity(
            activeTask.ingredients || [],
            taskEditType.TASK,
            task_uuid,
            recipeQuantity
          );

        payload["output_quantity"] =
          payload["output_quantity"] /
          (prepAndRecipeQuantities?.recipeQtyRatio || 1);

        handleTaskItemUpdate(payload, task_uuid);
      }
    }

    function getUpdatedIngredientData(ingredient: any) {
      // const currentQty: number =
      //   (ingredient?.unitConversion?.conversionFactor || 1) *
      //   ingredient.quantity;

      delete ingredient?.unitUuid;
      // delete ingredient?.unitConversion;
      return ingredient;
    }
  };

  const onSaveTaskInterventions = (
    formData: any,
    interventionUuid: string,
    taskId: string
  ) => {
    const prepAndRecipeQuantities: any = getPrepAndRecipeQuantities();
    const interventionId: string = interventionUuid.split("_")[0];
    const params: any = {
      interventionId,
      taskId,
      preparationSet: selectedPrepSet.value,
    };
    if (selectedTaskDetails?.dependent_tasks?.length > 0) {
      formData["prepared_items"] = selectedTaskDetails?.dependent_tasks?.map(
        (task: any) => task.task_uuid
      );
    } else if (formData?.prepared_items) {
      formData["prepared_items"] = formData?.prepared_items?.map(
        (task: any) => task.task_uuid
      );
    }

    // TODO  Create  a common function for steps and ingredient
    const parentTaskQuantity: any = calculateIngredientQty(
      selectedTaskDetails.parentTask,
      prepAndRecipeQuantities.recipeQtyRatio,
      {}
    );

    let taskRecipeQuantity: any = {};
    Object.keys(parentTaskQuantity).forEach((key: string) => {
      taskRecipeQuantity[key] = {
        assignedQuantity: 0,
        availableQuantity:
          (parentTaskQuantity[key]?.assignedQuantity || 0) *
          (parentTaskQuantity[key]?.unit?.conversionFactor || 1),
        totalRecipeQuantity:
          (parentTaskQuantity[key]?.assignedQuantity || 0) *
          (parentTaskQuantity[key]?.unit?.conversionFactor || 1),
        unit: parentTaskQuantity[key]?.unit,
      };
    });

    const recipeIngredientQty: any = getIngredientsQuantity(
      props.ingredients,
      prepAndRecipeQuantities.recipeQtyRatio
    );

    let totalRecipeIngredientQty: any = recipeIngredientQty.recipeQuantity;

    recipeTasks?.tasks.forEach((task: any) => {
      const taskDetails: any = _.cloneDeep(task);

      // if (task.uuid === selectedTaskDetails.parentTask.uuid) return;

      if (taskDetails?.ingredients?.length > 0)
        taskDetails["ingredients"] = getIngredientsWithPreRelatedQty(
          taskDetails["ingredients"],
          "taskSelection"
        );

      totalRecipeIngredientQty = calculateIngredientQty(
        taskDetails,
        1,
        totalRecipeIngredientQty
      );
    });

    const recipeQuantity: any = calculateUsedIngredientsInStepsAndIntervention(
      selectedTaskDetails.parentTask,
      prepAndRecipeQuantities.recipeQtyRatio,
      taskRecipeQuantity
    );

    if (selectedTaskDetails?.ingredients) {
      const ingredients = _.cloneDeep(selectedTaskDetails.ingredients).map(
        (ing: any) => {
          let conversionFactor: number = ing?.unitConversion?.conversionFactor
            ? ing?.unitConversion?.conversionFactor
            : ing?.unitConversionDetails?.conversionDetails?.conversionFactor
            ? ing.unitConversionDetails.conversionDetails.conversionFactor
            : undefined;

          if (!conversionFactor) {
            const ingredientDetails: any = recipeIngredients.find(
              (rI: any) => rI?.recipeUuid === ing.recipe_uuid
            );
            conversionFactor = ingredientDetails?.unitConversionDetails
              ?.conversionDetails?.conversionFactor
              ? ingredientDetails.unitConversionDetails.conversionDetails
                  .conversionFactor
              : 1;
          }

          let quantity: number = conversionFactor * ing.quantity;
          // if (prepAndRecipeQuantities?.recipeQtyRatio) {
          //   quantity = quantity / prepAndRecipeQuantities?.recipeQtyRatio;
          // }
          taskRecipeQuantity[ing.recipe_uuid]["assignedQuantity"] += quantity;
          taskRecipeQuantity[ing.recipe_uuid]["availableQuantity"] -= quantity;

          ing.quantity = quantity / prepAndRecipeQuantities.recipeQtyRatio;

          if (taskRecipeQuantity[ing.recipe_uuid]["availableQuantity"] < 0) {
            ing["task_quantity"] =
              taskRecipeQuantity[ing.recipe_uuid]["assignedQuantity"] /
              prepAndRecipeQuantities.recipeQtyRatio;
          }
          const totalRecipeQuantity =
            totalRecipeIngredientQty[ing.recipe_uuid]["totalRecipeQuantity"] *
            (totalRecipeIngredientQty[ing.recipe_uuid]?.unit
              ?.conversionFactor || 1);
          const availableRecipeQuantity =
            totalRecipeIngredientQty[ing.recipe_uuid]["availableQuantity"] *
            (totalRecipeIngredientQty[ing.recipe_uuid]?.unit
              ?.conversionFactor || 1);
          const remainingRecipeQty: number =
            availableRecipeQuantity +
            taskRecipeQuantity[ing.recipe_uuid]["availableQuantity"];
          if (ing["task_quantity"] && remainingRecipeQty < 0) {
            const additionalQty: number =
              totalRecipeQuantity + -remainingRecipeQty;
            ing["recipe_quantity"] =
              additionalQty / prepAndRecipeQuantities.recipeQtyRatio;
          }

          delete ing?.type;
          delete ing?.unitUuid;
          delete ing?.unitConversion;
          delete ing?.uuid;
          delete ing?.relatedUnits;
          delete ing?.hasRelatedQuantityUnit;
          delete ing?.unitConversionDetails;
          delete ing?.realUnit;

          return ing;
        }
      );
      formData["ingredients"] = ingredients || [];
    }

    handleTaskInterventionSave(formData, params);
  };

  const onSaveTaskSteps = (formData: any, stepUuid: string, taskId: string) => {
    const prepAndRecipeQuantities: any = getPrepAndRecipeQuantities();
    const stepId: string = stepUuid.split("_")[0];
    const params: any = {
      stepId,
      taskId,
      preparationSet: selectedPrepSet.value,
    };
    if (selectedTaskDetails?.dependent_tasks) {
      formData["prepared_items"] = selectedTaskDetails?.dependent_tasks?.map(
        (task: any) => task.task_uuid
      );
    } else if (formData?.prepared_items) {
      formData["prepared_items"] = formData?.prepared_items?.map(
        (task: any) => task.task_uuid
      );
    }

    // TODO  Create  a common function for steps and ingredient

    const parentTaskQuantity: any = calculateIngredientQty(
      selectedTaskDetails.parentTask,
      prepAndRecipeQuantities.recipeQtyRatio,
      {}
    );

    let taskRecipeQuantity: any = {};
    Object.keys(parentTaskQuantity).forEach((key: string) => {
      taskRecipeQuantity[key] = {
        assignedQuantity: 0,
        availableQuantity:
          (parentTaskQuantity[key]?.assignedQuantity || 0) *
          (parentTaskQuantity[key]?.unit?.conversionFactor || 1),
        totalRecipeQuantity:
          (parentTaskQuantity[key]?.assignedQuantity || 0) *
          (parentTaskQuantity[key]?.unit?.conversionFactor || 1),
        unit: parentTaskQuantity[key]?.unit,
      };
    });

    const recipeIngredientQty: any = getIngredientsQuantity(
      props.ingredients,
      prepAndRecipeQuantities.recipeQtyRatio
    );

    let totalRecipeIngredientQty: any = recipeIngredientQty.recipeQuantity;

    recipeTasks?.tasks.forEach((task: any) => {
      const taskDetails: any = _.cloneDeep(task);

      if (taskDetails?.ingredients?.length > 0)
        taskDetails["ingredients"] = getIngredientsWithPreRelatedQty(
          taskDetails["ingredients"],
          "taskSelection"
        );
      totalRecipeIngredientQty = calculateIngredientQty(
        taskDetails,
        1,
        totalRecipeIngredientQty
      );
    });

    const recipeQuantity: any = calculateUsedIngredientsInStepsAndIntervention(
      selectedTaskDetails.parentTask,
      prepAndRecipeQuantities.recipeQtyRatio,
      taskRecipeQuantity
    );

    if (selectedTaskDetails?.ingredients) {
      const ingredients = _.cloneDeep(selectedTaskDetails.ingredients).map(
        (ing: any) => {
          let conversionFactor: number = ing?.unitConversion?.conversionFactor
            ? ing?.unitConversion?.conversionFactor
            : ing?.unitConversionDetails?.conversionDetails?.conversionFactor
            ? ing.unitConversionDetails.conversionDetails.conversionFactor
            : undefined;
          if (!conversionFactor) {
            const ingredientDetails: any = recipeIngredients.find(
              (rI: any) => rI?.recipeUuid === ing.recipe_uuid
            );
            conversionFactor = ingredientDetails?.unitConversionDetails
              ?.conversionDetails?.conversionFactor
              ? ingredientDetails.unitConversionDetails.conversionDetails
                  .conversionFactor
              : 1;
          }

          let quantity: number = conversionFactor * ing.quantity;
          // if (prepAndRecipeQuantities?.recipeQtyRatio) {
          //   quantity = quantity / prepAndRecipeQuantities?.recipeQtyRatio;
          // }

          // if (
          //   typeof taskRecipeQuantity[ing.recipe_uuid]?.["assignedQuantity"] ===
          //   "undefined"
          // ) {
          //   taskRecipeQuantity[ing.recipe_uuid] = {
          //     assignedQuantity: 0,
          //     availableQuantity: 0,
          //   };
          // }

          taskRecipeQuantity[ing.recipe_uuid]["assignedQuantity"] += quantity;
          taskRecipeQuantity[ing.recipe_uuid]["availableQuantity"] -= quantity;

          ing.quantity = quantity / prepAndRecipeQuantities.recipeQtyRatio;

          if (taskRecipeQuantity[ing.recipe_uuid]["availableQuantity"] < 0) {
            ing["task_quantity"] =
              taskRecipeQuantity[ing.recipe_uuid]["assignedQuantity"] /
              prepAndRecipeQuantities.recipeQtyRatio;
          }
          const totalRecipeQuantity =
            totalRecipeIngredientQty[ing.recipe_uuid]["totalRecipeQuantity"] *
            (totalRecipeIngredientQty[ing.recipe_uuid]?.unit
              ?.conversionFactor || 1);
          const availableRecipeQuantity =
            totalRecipeIngredientQty[ing.recipe_uuid]["availableQuantity"] *
            (totalRecipeIngredientQty[ing.recipe_uuid]?.unit
              ?.conversionFactor || 1);

          const remainingRecipeQty: number =
            availableRecipeQuantity +
            taskRecipeQuantity[ing.recipe_uuid]["availableQuantity"];
          if (ing["task_quantity"] && remainingRecipeQty < 0) {
            const additionalQty: number =
              totalRecipeQuantity + -remainingRecipeQty;
            ing["recipe_quantity"] =
              additionalQty / prepAndRecipeQuantities.recipeQtyRatio;
          }

          delete ing?.type;
          delete ing?.unitUuid;
          delete ing?.unitConversion;
          delete ing?.uuid;
          delete ing?.relatedUnits;
          delete ing?.hasRelatedQuantityUnit;
          delete ing?.unitConversionDetails;
          delete ing?.realUnit;
          return ing;
        }
      );
      formData["ingredients"] = ingredients || [];
    }

    handleTaskStepSave(formData, params);
  };

  const onTaskEdit = (cardItemIdWithParent: string, formData: any) => {
    const cardItemId: string = cardItemIdWithParent.split("_")[0];

    setSelectedTaskDetails((prevState: any) => {
      let data: any = { ...prevState };

      if (cardItemId === "newTask") {
        //Need to remove this
        data = { ...taskDraft };
      } else if (cardItemId === "newTaskWithIng") {
        const tasks = recipeTasks.tasks;
        data = {
          ...taskWithIngDraft,
          dependent_tasks:
            tasks.length > 0
              ? [{ task_uuid: tasks[tasks.length - 1]?.uuid }]
              : [],
        };
      } else {
        const parentTaskDtls: any = recipeTasks.tasks?.find(
          (task: any) => task.uuid === formData.parentTaskId
        );
        if (cardItemId === "newIntervention" || cardItemId === "newStep") {
          if (parentTaskDtls?.ingredients?.length > 0)
            parentTaskDtls["ingredients"] = getIngredientsWithPreRelatedQty(
              parentTaskDtls["ingredients"],
              "taskSelection"
            );
          data = {
            type:
              cardItemId === "newIntervention"
                ? taskEditType.INTERVENTION
                : taskEditType.STEP,
            uuid: cardItemId,
            parentTask: parentTaskDtls,
            selectedPrepSet: selectedPrepSet,
          };
        } else if (cardItemId === "newInterventionStep") {
          const parentInterventionDtls: any =
            parentTaskDtls.interventions?.find(
              (intervention: any) =>
                intervention.uuid === formData.parentInterventionId
            );
          data = {
            type: taskEditType.INTERVENTION_STEP,
            uuid: cardItemId,
            parentTask: parentTaskDtls,
            parentIntervention: parentInterventionDtls,
            selectedPrepSet: selectedPrepSet,
          };
        }
      }
      data.isEdit = true;
      return data;
    });
  };

  const calculateSumOfAvailableQuantity = (data: any): number => {
    let sum = 0;

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        sum += data[key].availableQuantity;
      }
    }

    return sum;
  };

  const totalAvailableIngredientQuantity = _.isEmpty(quantityWarnings)
    ? 0
    : calculateSumOfAvailableQuantity(quantityWarnings);
  const renderIngredients = () => {
    return (
      <RecipeIngredients
        key={"ingredients"}
        addNewIngredientItem={addNewIngredientItem}
        cancelIngredientEdit={handleIngredientEditCancel}
        deleteIngredient={handleDeleteIngredient}
        getQuantityRelatedDetails={getQuantityRelatedDetails}
        getRecipeDetails={getRecipeDetails}
        getRecipeDetailsAsync={getRecipeDetailsAsync}
        getRecipeIcon={getRecipeIcon}
        getRelatedUnitOptions={getRelatedUnitOptions}
        getSupplierDetails={getSupplierDetails}
        handleIngredientEdit={handleIngredientEdit}
        handleIngredientsSave={applyIngredientsSave}
        handleIngredientsSorting={applyIngredientsSorting}
        handleOutputUnitSelection={handleOutputUnitSelection}
        handleTaskIngredientSelection={handleTaskIngredientSelection}
        ingredientDraft={ingredientDraft}
        ingredients={recipeIngredients}
        ingredientsQuantityData={quantityWarnings}
        labels={labels}
        onEditIngredients={onEditIngredients}
        otherRecipesLists={otherRecipesLists}
        prepAndRecipeQuantities={getPrepAndRecipeQuantities()}
        selectedTaskDetails={selectedTaskDetails}
      />
    );
  };

  const renderTasks = () => {
    return (
      <RecipeTasks
        tasks={recipeTasks?.tasks || []}
        getInputReadOnlyLabels={getInputReadOnlyLabels}
        getUnitDetails={getUnitDetails}
        getUuidFromMediaManager={getUuidFromMediaManager}
        handleTaskDependencySelection={handleTaskDependencySelection}
        handleTaskInterventionSave={onSaveTaskInterventions}
        handleTaskSelection={handleTaskSelection}
        handleTaskStepSave={onSaveTaskSteps}
        machinesOptions={getMachinesOptions()}
        onCancelEdit={onCancelTaskEdit}
        handleTaskInterventionDelete={handleTaskInterventionDelete}
        itemDraft={itemDraft}
        onDeleteTaskItem={onDeleteTaskItem}
        onSaveTaskItem={onSaveTaskItem}
        onItemModify={onItemModify}
        onTaskEdit={onTaskEdit}
        restLang={restaurantLang[0]?.code}
        selectedTaskDetails={selectedTaskDetails}
        stationsOptions={getStationsOptions()}
        taskDraft={taskDraft}
        taskWithIngDraft={taskWithIngDraft}
        unitOptions={getUnitOptions()}
        assembleTaskLocales={assembleTaskLocales}
        showNewTaskWithIng
        selectedPreparationSet={selectedPrepSet}
      />
    );
  };

  const resetIngredientsList = () => {
    let ingredients: Array<any> = [];
    if (Array.isArray(props.ingredients))
      ingredients = getIngredientsWithPreRelatedQty(props.ingredients);
    setRecipeIngredients(ingredients);
  };

  const resetTasksList = () => {
    let tasks: any = [];

    if (Array.isArray(props.tasks)) {
      const tasksLts = _.cloneDeep(props.tasks);
      tasks =
        getTasksPreparedToDisplay(
          tasksLts,
          recipeDetails.status === RECIPE_STATUS.DRAFT
        ) || [];
    }
    setSelectedTaskDetails({});
    setRecipeTasks(tasks);
  };

  const savePrepSet = () => {
    if (!selectedCalcUnit?.value || calculationQty.trim() === "") return;
    showLoader(true);
    const formData: any = {
      calculation_output_unit: selectedCalcUnit.value,
      calculation_output_quantity: Number(calculationQty),
      locales: {
        [restaurantLang[0].code]: { name: newPrepSet },
      },
      maintain_ingredient_quantities: maintainIngQty,
    };
    const payload: any = {
      credentials: {
        recipeUuid: recipeDetails.uuid,
        restaurantUuid: restaurantId,
        data: formData,
      },
    };

    if (editPrepData.current?.value) {
      payload.credentials.prepId = editPrepData.current.value;
      updatePreparationSets(RESTAURANT_REQUEST, payload)
        .then((response) => {
          if (response.status === 200) {
            showLoader(false);
            commonService.toastService(
              t("common.updatedSuccessFully"),
              "success"
            );
            const updatedOption = {
              calculationQty: formData.calculation_output_quantity,
              calculationUnit: formData.calculation_output_unit,
              active: editPrepData.current?.active || false,
              label: newPrepSet,
              value: editPrepData.current.value,
            };
            setSelectedPrepSet(updatedOption);
            editPrepData.current = {};
            getPrepSetsData();
            setNewPrepSet("");
            setPrepSetEditEnabled(false);
            setPrepSetCurrentAction("cancel");
          } else {
            showLoader(false);
            showErrorToast(response.data.errors);
          }
        })
        .catch((error: any) => {
          showLoader(false);
          showErrorToast(error);
        });
    } else {
      setPreparationSets(RESTAURANT_REQUEST, payload)
        .then((response) => {
          if (response.status === 200) {
            showLoader(false);
            commonService.toastService(
              t("common.updatedSuccessFully"),
              "success"
            );
            newPrepSetData.current = response.data.data;
            setPrepSetEditEnabled(false);
            setPrepSetCurrentAction("cancel");
            getPrepSetsData();
          } else {
            showLoader(false);
            showErrorToast(response.data.flash);
          }
        })
        .catch((error: any) => {
          showLoader(false);
          commonService.toastService("", "danger", JSON.stringify(error.error));
        });
    }
  };

  const setCalculationQtyAndUnit = (selectedSet?: any) => {
    let selectedPrep: any = selectedSet;
    if (!selectedPrep) {
      selectedPrep = selectedPrepSet;
    }
    if (
      !selectedPrep ||
      !selectedPrep?.calculationQty ||
      !selectedPrep?.calculationUnit
    ) {
      setCalculationQty("");
      setSelectedCalcUnit(null);
      return;
    }
    const { calculationQty, calculationUnit } = selectedPrep;
    const calcUnit: any = prepSetCalcUnitsOptions?.find(
      (unit: any) => unit.value === calculationUnit
    );
    setCalculationQty(String(calculationQty));
    if (calcUnit) setSelectedCalcUnit(calcUnit);
  };

  const setPrepEditable = () => {
    editPrepData.current = selectedPrepSet;
    setNewPrepSet(editPrepData.current?.label || "");
  };

  const setPrepSetAndRelatedStates = (options: any) => {
    const selectedPrepSetValue: string =
      newPrepSetData.current?.uuid || selectedPrepSet?.value;
    let selectedOption: any = options.find(
      (option: any) => option.value === selectedPrepSetValue
    );

    if (!selectedOption) {
      const activePrepSet: string = recipeDetails?.active_preparation_set;
      selectedOption = options.find(
        (option: any) => option.value === activePrepSet
      );

      if (!selectedOption) {
        selectedOption = options[0];
      } else {
        selectedOption.active = true;
      }
    }
    if (newPrepSetData.current?.uuid) newPrepSetData.current = {};
    handlePrepSetChange(selectedOption);
    setSelectedPrepSet(selectedOption);
    setCalculationQtyAndUnit(selectedOption);
  };

  const setRecipeRelatedUnitOptions = () => {
    const recipeUnitOptions: Array<any> = getRelatedUnits(
      recipeDetails.output_unit_uuid,
      recipeDetails.uuid
    );
    setPrepSetCalcUnitsOptions(recipeUnitOptions);
  };

  const sortTasks = (
    tasks: any,
    taskMap: any,
    sortedTasks: any,
    recipeEditable: boolean,
    includeNewTask: boolean = false
  ) => {
    let separateParentConnection: boolean = false;
    let circularParentConnection: boolean = false;

    let startingPoint = 0;

    taskMap.forEach((task: any, uuid: any) => {
      if (task.currentCount === 0 && !task.sorted) {
        walkTaskTree(uuid, taskMap, tasks, sortedTasks, recipeEditable);
        if (!task.task.isNewItem || includeNewTask) {
          startingPoint++;
          if (startingPoint > 1) {
            separateParentConnection = true;
          }
        }
      }
    });

    for (let i = tasks.length - 1; i >= 0; i--) {
      if (!taskMap.get(tasks[i].uuid).sorted) {
        circularParentConnection = true;
        addTaskToSortedTasks(tasks, sortedTasks, tasks[i].uuid, recipeEditable);
      }
    }

    let taskWarning = [];
    if (circularParentConnection) {
      taskWarning.push({
        type: "stop",
        text: t("task.circularParentConnectionWarning"),
      });
    }
    if (separateParentConnection) {
      taskWarning.push({
        type: "stop",
        text: t("task.multipleParentTaskWarning"),
      });
    }

    return { tasks: sortedTasks, taskWarning: taskWarning };
  };

  const togglePrepEdit = (area: string = "add") => {
    if (prepSetEditEnabled) {
      editPrepData.current = {};
    }

    if (area === "edit" || area === "calcEdit") {
      setPrepEditable();
      setMaintainIngQty(false);
    } else if (area === "add") {
      setCalculationQty("");
      setNewPrepSet("");
      setSelectedCalcUnit(null);
    } else if (area === "cancel") {
      setCalculationQtyAndUnit(selectedPrepSet);
    }
    const actions = ["add", "edit", "cancel"];
    if (actions.includes(area)) {
      setPrepSetEditEnabled(!prepSetEditEnabled);
    }
    setPrepSetCurrentAction(area);
  };

  const walkTaskTree = (
    uuid: any,
    taskMap: Map<any, any>,
    tasks: any[],
    sortedTasks: any[],
    recipeEditable: boolean
  ) => {
    addTaskToSortedTasks(tasks, sortedTasks, uuid, recipeEditable);

    let currentTask = taskMap.get(uuid);
    currentTask.sorted = true;

    currentTask.parent?.forEach((parent: any) => {
      let newTask = taskMap.get(parent);
      newTask.currentCount--;
      if (newTask.currentCount === 0) {
        walkTaskTree(parent, taskMap, tasks, sortedTasks, recipeEditable);
      }
    });
  };
  const prepSetPayload: any = {
    calculationQty,
    newPrepSet,
    preparationSetsOptions,
    prepSetCalcUnitsOptions,
    prepSetEditEnabled,
    selectedCalcUnit,
    selectedPrepSet,
    maintainIngQty,
    prepSetCurrentAction,
  };
  const prepSetActions: any = {
    activatePrepSet,
    clonePrepSet,
    deletePrepSet,
    handleChange,
    handlePrepSetCalcUnit,
    handlePrepSetSelection,
    savePrepSet,
    togglePrepEdit,
  };
  return (
    <section className="dim-box mb-4">
      <RecipePreparationSet
        payload={prepSetPayload}
        prepSetActions={prepSetActions}
      />

      {props.units?.length > 0 && (
        <Row className="app-row-padding">
          <Col xs={12} md={7} lg={12} xl={7}>
            <h4 className="align-items-center d-flex">
              {t("task.tasks")}
              <WarningComment data={recipeTasks?.taskWarning || null} />
            </h4>
            {renderTasks()}
          </Col>
          <Col xs={12} md={5} lg={12} xl={5}>
            {renderIngredients()}
          </Col>
        </Row>
      )}
    </section>
  );
}
export default RecipeTasksAndIngredientsWrapper;
