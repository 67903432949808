import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
  Input,
  FormGroup,
  ListGroup,
  ListGroupItem,
  CustomInput,
} from "reactstrap";
import LoaderComponent from "../loader/loader";

interface Item {
  uuid: string;
  description: string;
  groupName?: string;
}

interface MultiSelectListProps {
  id: string;
  items: Item[];
  initialSelectedUuids?: string[];
  onSelectionChange?: (selectedUuids: string[]) => void;
  pageSize?: number;
}

const MultiSelectList = ({
  id = "",
  items = [],
  initialSelectedUuids = [],
  onSelectionChange,
  pageSize = 20,
}: MultiSelectListProps): React.ReactElement | null => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState<string>("");
  const [selectedUuids, setSelectedUuids] = useState<Set<string>>(
    new Set(initialSelectedUuids)
  );
  const [displayedItemCount, setDisplayedItemCount] =
    useState<number>(pageSize);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const filteredItems = useMemo<Item[]>(() => {
    return items
      .filter(
        (item) =>
          item &&
          item.description &&
          item.uuid &&
          item.description.toLowerCase().includes(filter.toLowerCase())
      )
      .slice(0, displayedItemCount);
  }, [items, filter, displayedItemCount]);

  useEffect(() => {
    setSelectedUuids(new Set(initialSelectedUuids));
  }, [initialSelectedUuids]);

  const handleItemSelect = useCallback(
    (uuid: string) => {
      const newSelectedUuids = new Set(selectedUuids);

      if (newSelectedUuids.has(uuid)) {
        newSelectedUuids.delete(uuid);
      } else {
        newSelectedUuids.add(uuid);
      }

      setSelectedUuids(newSelectedUuids);

      if (onSelectionChange) {
        onSelectionChange(Array.from(newSelectedUuids));
      }
    },
    [selectedUuids, onSelectionChange]
  );

  const handleScroll = useCallback(() => {
    const container = scrollContainerRef.current;
    if (!container) return;

    if (
      container.scrollHeight - container.scrollTop <=
      container.clientHeight + 20
    ) {
      setDisplayedItemCount((prevCount) =>
        Math.min(prevCount + pageSize, items.length)
      );
    }
  }, [pageSize, items.length]);

  const handleClearFilter = useCallback(() => {
    setFilter("");
    setDisplayedItemCount(pageSize);
  }, [pageSize]);

  const listItems = () => {
    let groupName = "";
    return filteredItems.map((item: Item) => {
      const groupNode = item?.groupName && groupName !== item?.groupName && (
        <ListGroupItem
          key={`${item.uuid}-group`}
          className="d-flex align-items-center pl-2"
          style={{
            border: 0,
            borderBottom: "solid 1px rgba(0, 0, 0, .05)",
            borderRadius: 0,
            fontWeight: 500,
            color: "#8c8c8c",
          }}
        >
          {item.groupName}
        </ListGroupItem>
      );
      groupName = item?.groupName || "";
      return (
        <React.Fragment key={item.uuid}>
          {groupNode}
          <ListGroupItem
            key={item.uuid}
            className="d-flex align-items-center"
            style={{
              border: 0,
              borderBottom: "solid 1px rgba(0, 0, 0, .05)",
              borderRadius: 0,
            }}
          >
            <CustomInput
              type="checkbox"
              id={`${id}-item-${item.uuid}`}
              className="mr-2"
              checked={selectedUuids.has(item.uuid)}
              onChange={() => handleItemSelect(item.uuid)}
              label={item.description}
            />
          </ListGroupItem>
        </React.Fragment>
      );
    });
  };

  if (items.length === 0) {
    return (
      <div className="text-center text-muted p-4">
        {t("common.noItemsToDisplay")}
      </div>
    );
  }

  return (
    <div>
      <FormGroup style={{ margin: 0 }}>
        <div className="position-relative">
          <Input
            type="text"
            name={`${id}-filter`}
            id={`${id}-filter`}
            placeholder={`${t("common.search")}...`}
            value={filter}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFilter(e.target.value);
              setDisplayedItemCount(pageSize);
            }}
            style={{
              borderRadius: "4px 4px 0 0",
              paddingRight: filter ? "30px" : undefined,
            }}
          />
          {filter && (
            <button
              type="button"
              onClick={handleClearFilter}
              style={{
                position: "absolute",
                right: "10px",
                top: "50%",
                transform: "translateY(-50%)",
                background: "none",
                border: "none",
                color: "#6c757d",
                cursor: "pointer",
                zIndex: 10,
              }}
            >
              ✕
            </button>
          )}
        </div>
      </FormGroup>

      <div
        ref={scrollContainerRef}
        onScroll={handleScroll}
        style={{
          height: "400px",
          overflowY: "auto",
          border: "1px solid #ddd",
          borderRadius: "0 0 4px 4px",
          borderTop: 0,
        }}
      >
        <ListGroup>{listItems()}</ListGroup>
        <LoaderComponent display={displayedItemCount < items.length} />
      </div>
    </div>
  );
};

export default MultiSelectList;
