import React from "react";
import {
  Button,
  Col,
  CustomInput,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import Nouislider from "nouislider-react";
import Select from "react-select";
import "nouislider/distribute/nouislider.css";
import "../../../styles/tag-assign.scss";

import i18next from "i18next";
import TagValue from "../../tags/tagValue";
import { useTranslation } from "react-i18next";
import { TAG_DATATYPE } from "../../../constant/constant";
import { isEmpty } from "lodash";

export default function TagAssignCard(props: any) {
  const { t, i18n } = useTranslation();
  const selectedTag = { ...props.state.tagName };
  const restriction = selectedTag?.meta?.restriction || {};

  const getTagOptions = () => {
    return props.state.tagOptions?.filter((item: any) => {
      return !props.itemTags?.find((element: any) => {
        if (item.value === props.state.tagId) return false;
        else return element.tag.uuid === item.value;
      });
    });
  };

  return (
    <Modal isOpen={props.showAddTag} size={"md"}>
      <ModalHeader toggle={props.addTagModal} className="modal-header">
        {t("tag.assignTag")}
      </ModalHeader>
      <ModalBody>
        <Form className="tag-assign">
          <FormGroup row className="p-0">
            <Label className="text-right" for="tagName" sm={4}>
              {t("tag.tag")}
              <span className="required-star-style">*</span>
            </Label>
            <Col sm={8}>
              <Select
                maxMenuHeight={200}
                name="tagName"
                onChange={(val: any) => {
                  props.handleTagChange(val, "tagName");
                }}
                options={getTagOptions()}
                value={selectedTag}
              />
            </Col>
          </FormGroup>
          {[TAG_DATATYPE.NUMBER, TAG_DATATYPE.TEXT].includes(
            selectedTag?.datatype
          ) &&
            !isEmpty(restriction?.type) && (
              <FormGroup row className="p-0">
                <Label className="text-right" sm={4}>
                  {t("common.restriction")}
                </Label>
                <Col sm={8} className="pt-2">
                  {restriction?.type === "range" && (
                    <>
                      <Nouislider
                        connect
                        onUpdate={(val: any) =>
                          props.handleTagChange(
                            {
                              type: "range",
                              from: parseInt(val[0]),
                              to: parseInt(val[1]),
                            },
                            "tagRestriction"
                          )
                        }
                        range={{
                          min: restriction.from,
                          max: restriction.to,
                        }}
                        start={[
                          props.state?.tagRestriction?.from,
                          props.state?.tagRestriction?.to,
                        ]}
                        step={1}
                      />
                      <div className="mt-3 bf-font-size-12">
                        <span className="">{t("common.min")} :</span>{" "}
                        {Number(props.state?.tagRestriction?.from)},
                        <span className="bf-font-size-12">
                          {t("common.max")} :
                        </span>{" "}
                        {Number(props.state?.tagRestriction?.to)}
                      </div>
                    </>
                  )}

                  {restriction?.type === "option" && (
                    <Select
                      maxMenuHeight={200}
                      name="tagOptionValue"
                      onChange={(val: any) => {
                        props.handleTagChange(
                          {
                            type: "option",
                            options: val?.map((item: any) => item.value),
                          },
                          "tagRestriction"
                        );
                        props.handleTagChange(val, "tagOptionValue");
                      }}
                      options={restriction?.options?.map((item: any) => ({
                        label: item,
                        value: item,
                      }))}
                      value={props.state.tagOptionValue}
                      isMulti
                    />
                  )}
                </Col>
              </FormGroup>
            )}
          {selectedTag?.datatype && (
            <TagValue
              fieldLabel={t("common.value")}
              fieldName="tagValue"
              fieldValue={props.state.tagValue}
              handleChange={props.handleChange}
              tagDatatype={selectedTag?.datatype}
              restriction={props.state.tagRestriction}
            />
          )}
          {selectedTag?.datatype === TAG_DATATYPE.CONTENT && (
            <FormGroup row className="p-0">
              <Label
                className={
                  selectedTag?.meta?.type !== 2 ? "text-right" : "text-right"
                }
                for="content"
                sm={4}
              >
                {t("common.value")}
              </Label>
              <Col sm={8} className="">
                <Select
                  maxMenuHeight={200}
                  name="tagValue"
                  onChange={(val: any) => {
                    props.handleTagChange(val, "tagValue");
                  }}
                  options={props.contentList}
                  value={props.state.tagValue}
                />
              </Col>
            </FormGroup>
          )}

          <FormGroup row className="p-0">
            <Label
              className={"text-right"}
              for="override"
              sm={props.state.richText ? 2 : 4}
            >
              {t("common.override")}
            </Label>
            <Col sm={8} className="d-flex pt-2">
              <CustomInput
                checked={props.state.override}
                id="override"
                label=""
                name="override"
                onChange={props.handleChange}
                type="switch"
              />
              <span className="ml-2">
                {String(props.state.override).charAt(0).toUpperCase() +
                  String(props.state.override).slice(1)}
              </span>
            </Col>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={props.addTagModal}>
          {t("common.cancel")}
        </Button>
        <Button
          color="info"
          onClick={() => {
            props.saveTag();
          }}
          disabled={!(selectedTag && selectedTag.datatype)}
        >
          {t("common.save")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
