import apiCall from "./api.call.config";

export async function getIngredients(url: string, params: any) {
  let urls =
    url +
    "/" +
    params.credentials.restaurantuuid +
    "/recipe/list-paged?sort=name:asc&type=[" +
    params.credentials.type +
    "]&pageNo=" +
    params.credentials.pageNo +
    "&pageSize=20&filter=label=[" +
    params.credentials.labelArray +
    "]||" +
    "supplier=[" +
    params.credentials.supplierArray +
    "]||" +
    "name=" +
    params.credentials.name +
    "||status=" +
    params.credentials.status;
  let urlString = encodeURI(urls);
  try {
    return await apiCall.get(urlString);
  } catch (e) {
    throw e;
  }
}

export async function getIngredientsReplacement(url: string, params: any) {
  try {
    return await apiCall.post(
      url + "/" + params.restaurantuuid + "/recipe/replacement-items",
      params.data
    );
  } catch (e) {
    throw e;
  }
}

export async function getItemSupplierInventoryInfo(url: string, params: any) {
  try {
    return await apiCall.post(
      url + "/" + params.restaurantuuid + "/recipe/supplier-inventory-info",
      params.data
    );
  } catch (e) {
    throw e;
  }
}

export default {
  getIngredients,
  getIngredientsReplacement,
  getItemSupplierInventoryInfo,
};
