import React from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useTranslation } from "react-i18next";

interface props {
  isOpen: boolean;
  onCancel: any;
  onSave: React.MouseEventHandler<any>;
  onEdit: React.MouseEventHandler<any>;
  handleChange: React.ChangeEventHandler<HTMLInputElement>;
  uuid?: string;
  description?: string;
  allowAppValidation?: boolean;
}
const QualityCriteriaEditModal = (props: props) => {
  const { t } = useTranslation();

  return (
    <div>
      <Modal isOpen={props.isOpen}>
        <ModalHeader toggle={props.onCancel}>
          {props.uuid
            ? t("recipe.editRecipeQualityCriteria")
            : t("recipe.addRecipeQualityCriteria")}
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup row className="p-0">
              <Col sm={12}>
                <Label className="text-right" for="description">
                  {t("recipe.qualityCriteria")}
                  <span className="required-star-style">*</span>
                </Label>
                <Input
                  type="textarea"
                  name="description"
                  value={props.description}
                  onChange={props.handleChange}
                />
              </Col>
            </FormGroup>
            <FormGroup row className="p-0 ml-2">
              <Col sm={12}>
                <Input
                  type="checkbox"
                  name="allowAppValidation"
                  id="allowAppValidation"
                  onChange={props.handleChange}
                  checked={props.allowAppValidation}
                />
                <Label for="allowAppValidation">
                  {t("recipe.allowAppValidation")}
                </Label>
              </Col>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={props.onCancel}>
            {t("common.cancel")}
          </Button>
          <Button
            color="info"
            onClick={props.uuid ? props.onEdit : props.onSave}
            disabled={props.description === ""}
          >
            {t("common.save")}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default QualityCriteriaEditModal;
