import React, { useState } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ButtonGroup,
} from "reactstrap";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import TagValue from "./tagValue";
import { TAG_DATATYPE } from "../../constant/constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

export default function TagsCreateEditModal(props: any) {
  const { t, i18n } = useTranslation();
  const { className, isOpen } = props;
  const [restrictionType, setRestrictionType] = useState<string>("option");
  const [errorMsg, setErrorMsg] = useState("");

  const setDefaultRestrictionType = (type: string = "option") => {
    setRestrictionType(type);
  };

  const handleDatatypeChange = (event: any) => {
    setDefaultRestrictionType();
    props.resetMetaData();
    props.handleChange(event);
  };

  const handleMount = () => {
    if (props.state.tags_datatype !== TAG_DATATYPE.NUMBER) {
      setDefaultRestrictionType();
    }
    if (props.state.isEdit) {
      setDefaultRestrictionType(props.state.restriction?.type);
    }
    setErrorMsg("");
  };

  const isNotValidForm = () => {
    const tagKeyRegex = /^([a-z.0-9-_]+)$/;
    const { tags_name, tags_key, tags_range_from, tags_range_to } = props.state;

    if (
      tags_name.trim() === "" ||
      tags_key.trim() === "" ||
      !tagKeyRegex.test(tags_key.trim()) ||
      (tags_range_from !== "" && tags_range_to === "") ||
      (tags_range_from === "" && tags_range_to !== "") ||
      (tags_range_from !== "" &&
        tags_range_to !== "" &&
        Number(tags_range_from) >= Number(tags_range_to))
    ) {
      return true;
    }
    return false;
  };

  const handleKeyChange = (e: any) => {
    props.handleChange(e);
    const tagKeyRegex = /^([a-z.0-9-_]+)$/;
    if (!tagKeyRegex.test(e.target.value.trim())) {
      setErrorMsg(t("tag.tagKeyError"));
    } else {
      setErrorMsg("");
    }
  };

  return (
    <div>
      <Modal isOpen={isOpen} className={className} onOpened={handleMount}>
        <ModalHeader toggle={props.onCancel}>
          {props.state.isEdit ? t("tag.editTag") : t("tag.createNewTag")}
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup row className="p-0">
              <Label className="text-right" for="tags_name" sm={4}>
                {t("common.name")}
                <span className="required-star-style">*</span>
              </Label>
              <Col sm={8}>
                <Input
                  type="text"
                  name="tags_name"
                  placeholder="Tag name"
                  value={props.state.tags_name}
                  onChange={props.handleChange}
                />
              </Col>
            </FormGroup>
            <FormGroup row className="p-0">
              <Label className="text-right" for="tags_key" sm={4}>
                {t("common.key")}
                <span className="required-star-style">*</span>
              </Label>
              <Col sm={8}>
                <Input
                  type="text"
                  name="tags_key"
                  placeholder="Tag key"
                  value={props.state.tags_key}
                  onChange={handleKeyChange}
                />
                <span className="d-flex bf-font-size-12 text-danger mt-1">
                  {errorMsg}
                </span>
              </Col>
            </FormGroup>
            {!props.state.isEdit && (
              <FormGroup row className="p-0">
                <Label className="text-right" for="tags_datatype" sm={4}>
                  {t("tag.datatype")}
                  <span className="required-star-style">*</span>
                </Label>
                <Col sm={8}>
                  <Input
                    type="select"
                    name="tags_datatype"
                    onChange={handleDatatypeChange}
                    value={props.state.tags_datatype}
                  >
                    <option value={TAG_DATATYPE.NUMBER}>
                      {t("common.number")}
                    </option>
                    <option value={TAG_DATATYPE.BOOLEAN}>
                      {t("common.boolean")}
                    </option>
                    <option value={TAG_DATATYPE.TEXT}>
                      {t("common.text")}
                    </option>
                    <option value={TAG_DATATYPE.CONTENT}>
                      {t("common.content")}
                    </option>
                  </Input>
                </Col>
              </FormGroup>
            )}

            {props.state.tags_datatype === TAG_DATATYPE.NUMBER && (
              <FormGroup row className="p-0">
                <Label className="text-right" sm={4}>
                  {t("common.restriction")}
                </Label>
                <Col sm={8}>
                  <ButtonGroup>
                    <Button
                      color="info"
                      outline
                      onClick={() => {
                        setRestrictionType("option");
                        props.handleChange({
                          target: {
                            name: "moreValues",
                            value: [...props.state.moreValues],
                          },
                        });
                      }}
                      active={restrictionType === "option"}
                    >
                      {t("common.option")}
                    </Button>
                    <Button
                      color="info"
                      outline
                      onClick={() => {
                        setRestrictionType("range");
                        props.handleChange({
                          target: {
                            name: "tags_range_from",
                            value: props.state.tags_range_from,
                          },
                        });
                      }}
                      active={restrictionType === "range"}
                    >
                      {t("common.range")}
                    </Button>
                  </ButtonGroup>
                </Col>
              </FormGroup>
            )}
            {restrictionType === "range" && (
              <FormGroup row className="p-0">
                <Label className="text-right" sm={4}>
                  {" "}
                </Label>
                <Col sm={8}>
                  <div className="row p-0">
                    <div className="col d-flex align-items-center">
                      <span className="mr-2">{t("common.from")}</span>
                      <Input
                        name="tags_range_from"
                        type="number"
                        value={props.state.tags_range_from}
                        onChange={props.handleChange}
                      />
                    </div>
                    <div className="col d-flex align-items-center pl-0">
                      <span className="mr-2">{t("common.to")}</span>
                      <Input
                        name="tags_range_to"
                        type="number"
                        value={props.state.tags_range_to}
                        onChange={props.handleChange}
                      />
                    </div>
                  </div>
                </Col>
              </FormGroup>
            )}
            {[TAG_DATATYPE.NUMBER, TAG_DATATYPE.TEXT].includes(
              props.state.tags_datatype
            ) &&
              restrictionType === "option" && (
                <FormGroup row className="p-0">
                  <Label className="text-right" for="tagValue" sm={4}>
                    {TAG_DATATYPE.TEXT === props.state.tags_datatype
                      ? t("common.restriction")
                      : ""}
                  </Label>
                  <Col sm={8} className="pt-2">
                    {props.state.moreValues?.map((item: any, index: any) => (
                      <div
                        key={index}
                        className="align-items-center d-flex flex-wrap"
                        style={{ justifyContent: "space-between" }}
                      >
                        <Input
                          key={index}
                          className={index !== 0 ? "mt-2 w-75" : "w-75"}
                          name="tagValue"
                          onChange={(e: any) =>
                            props.handleOptionsChange(e, index)
                          }
                          type={
                            TAG_DATATYPE.NUMBER === props.state.tags_datatype
                              ? "number"
                              : "text"
                          }
                          value={props.state.moreValues[index].value}
                        />
                        {index === 0 && (
                          <Button
                            className="ml-2"
                            size="sm"
                            color="info"
                            outline
                            disabled={props.state.moreValues?.some(
                              (item: any) => item.value === ""
                            )}
                            onClick={(e: any) => {
                              props.addMoreValues(e);
                            }}
                          >
                            <FontAwesomeIcon icon={faPlus} />
                          </Button>
                        )}
                        {index !== 0 && (
                          <Button
                            className="ml-2"
                            size="sm"
                            color="secondary"
                            outline
                            onClick={() => {
                              props.removeValue(index, props.state);
                            }}
                          >
                            <FontAwesomeIcon icon={faTrashAlt} />
                          </Button>
                        )}
                      </div>
                    ))}
                  </Col>
                </FormGroup>
              )}

            <TagValue
              fieldLabel={t("common.defaultValue")}
              fieldName="tag_default_value"
              fieldValue={props.state.tag_default_value}
              handleChange={props.handleChange}
              tagDatatype={props.state.tags_datatype}
              restriction={props.state.restriction}
            />
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={props.onCancel}>
            {t("common.cancel")}
          </Button>
          <Button
            color="info"
            onClick={props.onSave}
            disabled={isNotValidForm()}
          >
            {t("tag.saveTag")}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
