import React from "react";
import i18next from "i18next";
import { Table } from "reactstrap";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

export default function TagsList(props: any) {
  const { t, i18n } = useTranslation();
  return (
    <Table responsive hover>
      <thead>
        <tr>
          <th>{t("common.name")} </th>
          <th>{t("common.key")}</th>
          <th style={{ whiteSpace: "nowrap" }}>{t("tag.datatype")}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {props.tagList &&
          props.tagList.map((item: any, index: any) => {
            return (
              <tr key={item.uuid}>
                <td>{item.name}</td>
                <td>{item.key}</td>
                <td>{item.datatype ? t(`common.${item.datatype}`) : ""}</td>
                <td className="text-right text-nowrap ">
                  {item.global && (
                    <span className="badge badge-info">
                      {t("common.global")}
                    </span>
                  )}
                  {!item.global && (
                    <>
                      <span
                        className="px-1 pointer-cursor"
                        onClick={() => {
                          props.onEdit(item.uuid);
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faEdit}
                          style={{
                            fontSize: 16,
                          }}
                        />
                      </span>

                      <span
                        className="px-1 pointer-cursor"
                        onClick={() => {
                          props.onDelete(item.uuid);
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faTrashAlt}
                          style={{
                            fontSize: 16,
                          }}
                        />
                      </span>
                    </>
                  )}
                </td>
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
}
