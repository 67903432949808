import { TAG_DATATYPE } from "../constant/constant";

export interface TagsModel {
  readonly data: object;
  readonly error: object;
  readonly failure: boolean;
  readonly isFetching: boolean;
}

export interface TagsState {
  isAddEditTagModalOpen: boolean;
  isEdit: boolean;
  isFetching: boolean;
  isRemoveTagModalOpen: boolean;
  isUpdated: boolean;
  moreValues: Array<any>;
  restaurantuuid: string;
  tags: Array<any>;
  tags_name: string;
  tags_key: string;
  tags_range_from: string;
  tags_range_to: string;
  tags_datatype: string;
  tag_default_value: string;
  uuid: string;
  restriction: any;
}

export const TagsInitState = {
  isAddEditTagModalOpen: false,
  isEdit: false,
  isFetching: false,
  isRemoveTagModalOpen: false,
  isUpdated: false,
  moreValues: [],
  restaurantuuid: "",
  tags: [],
  tags_name: "",
  tags_key: "",
  tags_range_from: "",
  tags_range_to: "",
  tags_datatype: TAG_DATATYPE.NUMBER,
  tag_default_value: "",
  uuid: "",
  restriction: {},
};
