import React, { useEffect, useState } from "react";

import Select from "react-select";
import { Link } from "react-router-dom";
import { Editor } from "react-draft-wysiwyg";
import { convertToHTML } from "draft-convert";
import { useTranslation } from "react-i18next";
import { TAG_DATATYPE } from "../../constant/constant";
import { EditorState, ContentState, convertFromHTML } from "draft-js";
import { Col, CustomInput, FormGroup, Input, Label } from "reactstrap";

import "../../styles/draft-js.scss";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

interface TagValueProps {
  fieldLabel: string;
  fieldName: string;
  fieldValue: string;
  handleChange: Function;
  tagDatatype: string;
  restriction: any;
}

export default function TagValue(props: TagValueProps) {
  const { t } = useTranslation();
  const [isRichText, setIsRichText] = useState<boolean>(false);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  useEffect(() => {
    const isHtmlContent = /<([a-z][\s\S]*>|\s*\/?>)/i.test(props.fieldValue);
    if (
      isHtmlContent &&
      props.tagDatatype === TAG_DATATYPE.TEXT &&
      !isRichText
    ) {
      switchRichTextarea();
    }
  }, []);

  if (!props.tagDatatype || TAG_DATATYPE.CONTENT === props.tagDatatype)
    return null;

  const restrictionOptions =
    props.restriction?.type === "option" && props.restriction?.options
      ? props.restriction?.options.map((v: any) => {
          return { value: v, label: v };
        })
      : [];

  const fieldValueOption =
    restrictionOptions?.length > 0 && props.fieldValue
      ? restrictionOptions?.find((o: any) => o.value === props.fieldValue)
      : {};

  const restrictionRange =
    props.restriction?.type === "range" ? { ...props.restriction } : null;

  const isValidFieldValue =
    !restrictionRange ||
    props.fieldValue === "" ||
    (Number(props.fieldValue) >= restrictionRange.from &&
      Number(props.fieldValue) <= restrictionRange.to);

  const isTextareaEnabled = (): boolean => {
    return (
      TAG_DATATYPE.TEXT === props.tagDatatype && restrictionOptions.length === 0
    );
  };

  const handleEditorChange = (newState: any) => {
    setEditorState(newState);
    props.handleChange({
      target: {
        name: props.fieldName,
        value: convertToHTML(newState.getCurrentContent()),
      },
    });
  };

  const switchRichTextarea = () => {
    const blocksFromHTML = convertFromHTML(props.fieldValue);
    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );
    setEditorState(EditorState.createWithContent(state));

    setIsRichText((isRichText: boolean) => !isRichText);
  };

  return (
    <FormGroup row className="p-0">
      <Label className="text-right" for="default_value" sm={4}>
        {props.fieldLabel}
      </Label>
      <Col
        sm={8}
        className={
          TAG_DATATYPE.BOOLEAN === props.tagDatatype ? "d-flex pt-2" : ""
        }
      >
        {TAG_DATATYPE.BOOLEAN === props.tagDatatype && (
          <>
            <CustomInput
              checked={props.fieldValue === "true"}
              id={"enablex"}
              name={props.fieldName}
              type="switch"
              onChange={(e: any) => {
                props.handleChange({
                  target: {
                    name: props.fieldName,
                    value: props.fieldValue === "true" ? "false" : "true",
                  },
                });
              }}
            />
            <span className="ml-2">
              {String(props.fieldValue).charAt(0).toUpperCase() +
                String(props.fieldValue).slice(1)}
            </span>
          </>
        )}

        {TAG_DATATYPE.BOOLEAN !== props.tagDatatype &&
          restrictionOptions.length > 0 && (
            <Select
              name={props.fieldName}
              className="basic-single"
              classNamePrefix="select"
              isSearchable={true}
              value={fieldValueOption}
              options={restrictionOptions}
              onChange={(o: any) => {
                props.handleChange({
                  target: {
                    name: props.fieldName,
                    value: o.value,
                  },
                });
              }}
            />
          )}

        {TAG_DATATYPE.NUMBER === props.tagDatatype &&
          restrictionOptions.length === 0 && (
            <Input
              type="number"
              name={props.fieldName}
              placeholder={props.fieldLabel}
              value={props.fieldValue}
              onChange={(e: any) => {
                props.handleChange(e);
              }}
              invalid={!isValidFieldValue}
            />
          )}
        {isTextareaEnabled() && (
          <Link
            to="#"
            className="bf-font-size-14 cursor-pointer mt-3"
            onClick={switchRichTextarea}
            style={{ position: "absolute", right: "1rem", bottom: 0 }}
          >
            {isRichText ? t("common.simpleEdits") : t("common.advancedEdits")}
          </Link>
        )}
      </Col>
      {isTextareaEnabled() && (
        <Col sm={12}>
          {!isRichText ? (
            <textarea
              className="form-control"
              name={props.fieldName}
              onChange={(e) => {
                props.handleChange(e);
              }}
              value={props.fieldValue}
            />
          ) : (
            <Editor
              editorState={editorState}
              onEditorStateChange={handleEditorChange}
              wrapperClassName="draft-js-wrapper"
              editorClassName="draft-js-editor"
            />
          )}
        </Col>
      )}
    </FormGroup>
  );
}
